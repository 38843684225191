import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
import { getCompressPaymentLink } from '../services/apiServices';
import { useLocation } from "react-router-dom";

function PaymentLinkPage() {
    const [expired, setExpired] = useState(false);

    // const { id } = useParams();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id"); // Extract the `id` parameter

    const encryptedId = id;

    const fetchData = async () => {
        try {
            const response = await getCompressPaymentLink(encryptedId);
            const recinfo = await response.json();

            if (!recinfo || recinfo.status !== 0) {
                setExpired(true);
                console.error("Payment link expired or invalid");
                return;
            }

            const paymentUrl = recinfo.url;
            console.log("Generated payment url:", paymentUrl);

            if (window.location.origin.includes('localhost') || window.location.origin.includes('beta')) {
                if (window.confirm("Are you sure you want to open the link in a new tab?")) {
                    window.open(paymentUrl, "_blank");
                }
            } else {
                window.location.href = paymentUrl;
            }

        } catch (error) {
            console.error("Error fetching payment URL:", error);
            setExpired(true);
        }
    };

    useEffect(() => {
        if (encryptedId) fetchData();
    }, [encryptedId]);

    return (
        <>
            {expired && (
                <div className="d-flex align-items-center justify-content-center">
                    <div className="d-flex align-items-center justify-content-center flex-column py-4">
                        <h5>Payment Link Expired.</h5>
                    </div>
                </div>
            )}
        </>
    );
}

export default PaymentLinkPage;