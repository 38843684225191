import { MDBBadge } from 'mdb-react-ui-kit';
import { React, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import trancsacImg from "./../components/images/Transactions.svg";
import srcImg from "./../components/images/search.svg";
import { isMobile } from 'react-device-detect';

export default function SearchBar(props) {
  const searchRef = useRef();
  const [createdDate, setCreatedDate] = useState(new Date());
  const [cursor, SetCusor] = useState(0);
  const [searchflag, setSearchFlage] = useState(true);
  const [inputText, setInputText] = useState("");
  const [showResults, setResults] = useState(false);
  let handleKeyDown = (e) => {
    // arrow up/down button should select next/previous list element

    console.log(e.keyCode);
    if (e.keyCode === 38 && cursor > 0) {
      SetCusor(cursor - 1);
    } else if (e.keyCode === 40 && cursor < filteredData.length - 1) {
      SetCusor(cursor + 1);
    } else if (e.keyCode === 13) {
      selectPlan(e, filteredData[cursor].name, filteredData[cursor]._id);
      SetCusor(-1);
    } else if (e.keyCode === 27) {
      setResults(false);
      setInputText("");
      SetCusor(-1);
    }
  };
  let inputHandler = (e) => {
    //convert input text to lower case
    e.preventDefault();
    setSearchFlage(true);
    try {
      var lowerCase = e.target.value.toLowerCase();
      setInputText(lowerCase);
      setResults(true);
    } catch (e) { }
  };
  let filteredData = props.data.filter((el) => {
    try {
      if (searchflag) {
        if (inputText === "") {
          return el.enable == null || el.enable !== false;
        } else {
          return el.enable !== false && el.name.toLowerCase().includes(inputText.toLowerCase());
        }
      }
      return false;
    } catch (e) {
      return false;
    }
  });
  function CreatedDate(date, id) {
    // let tselObj = selItems.filter((tObj) => tObj._id === id)[0];
    // tselObj.cdate = date;
    setCreatedDate(date);
    handleTransDate(date)
  }

  function handleTransDate(date) {
    console.log("in searchbar handleTransDate ", date);
    props.handleTransdate(date)
  }
  function selectPlan(event, name, id) {
    props.onselect(event, id, name);
    setResults(false);
    setInputText("");
  }
  function handleClickOutside(event) {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setResults(false);
    }
  }
  if (props.value !== 0) {
    document.body.addEventListener('click', handleClickOutside);
  }
  useEffect(() => {
    console.log(props.searchflag)
    if (props.searchflag) {
      setSearchFlage(true
      )
    }
    if (props.value === 0) {
      setResults(true);
    } else {
      setResults(false);
    }
    searchRef.current.focus();
  }, [props.value]);

  return (
    <>
      <div
        className="gym-container  gym-cnr"
        id="showcase"
      >
        <div className="d-flex m-0 align-items-center justify-between w-50 mob-w-100 mb-3 mob-block w-sm-100">
          <h3 className="gym-large d-flex align-items-center POStitle m-0">{props.viewName}</h3>
          <div className="d-flex align-items-center ">
            {props.selItems.length > 0 &&
              <>
                <p className={isMobile ? "ms-3 mb-0 mt-2" : "ms-3 mb-0"} style={{ whiteSpace: "nowrap" }}>
                  <b>
                    Transaction Date
                  </b>
                </p>
                <div className="ms-2">
                  <DatePicker
                    selected={createdDate}
                    onChange={(date) => CreatedDate(date)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="MM-dd-yyyy h:mm aa"
                    className="inp-clrdate-Small1"
                  />
                </div>
              </>
            }
          </div>
        </div>
        <h3
          className="gym-xlarge gym-text-red"
          style={{ position: "relative" }}
        >
          <input
            ref={searchRef}
            type="text"
            className="gym-input forminput"
            placeholder={props.placeholder}
            value={inputText}
            onChange={inputHandler}
            onClick={() => setResults(true)}
            onKeyDown={handleKeyDown}
          />
          <img src={srcImg} width="20px" className="searchicon" alt="Search" />
        </h3>
      </div>
      {showResults && (
        <div className="searchbar-result gym-container">
          {filteredData.length > 0 &&
            filteredData.map((item, i) => (
              <div
                key={i}
                className={
                  cursor === i
                    ? "d-flex home-select-drp home-active"
                    : "d-flex home-select-drp"
                }
                style={{ cursor: "pointer" }}
                onClick={(event) => selectPlan(event, item.name, item._id)}
              >
                <div className="w-91 posSearchData_1 ">{item.name} <MDBBadge light className="ms-2" color={item.type === "plan" ? "primary" : "success"}>{item.type === "plan" ? "Plan" : "Addon"}</MDBBadge> </div>
                <div className="w-8 posSearchData_2">
                  <img className="imgCurrency" src={trancsacImg} alt="Price" />
                  {!item.price ? item.props[0].propvalue : item.price}
                </div>
              </div>
            ))}
        </div>
      )}
    </>
  );
}