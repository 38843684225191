
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import Loader from "../components/Loader";
import CreateMember from "../components/createMember";
import { getFormMembersLazy } from "../services/apiServices.js";
import srcImg from "./../components/images/search.svg";
import { MDBChip } from 'mdb-react-ui-kit';
import PageError from "./PageError";

function AddformMembers({ props, handleform }) {
    const [searchQuery, setSearchQuery] = useState("");
    const searchQueryRef = useRef(searchQuery);
    const [apiError, setApiError] = useState(false);
    const containerRef = useRef();
    const [error, setError] = useState({});
    const searchRef = useRef();
    const tpageRef = useRef(0); // Use useRef for tpage
    const [userData, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedMembers, setselectedMembers] = useState([]);
    const [showNMember, setshowNMember] = useState(false);

    // console.log(props);
    let showNewMember = (e) => {
        e.preventDefault();
        setshowNMember(true);
        handleToggleform()
    };

    const handleToggleform = () => {
        handleform()
    }

    // let page = localStorage.getItem("page")
    //     ? localStorage.getItem("page") * 1
    //     : 1;
    // // console.log(localStorage.getItem("page"));
    // // console.log(page);
    // function setPage(tpage) {
    //     if (tpage) {
    //         page = tpage;
    //     }
    //     localStorage.setItem("page", page);
    // }
    let hideCreateMember = (e, memInfo) => {
        if (memInfo) {
            selectedMembers.forEach((item) => {
                addMember(e, item, false);
                const nusers = usersData.filter((uitem) => uitem._id !== item._id);
                setData([item, ...nusers]);
            });
            addMember(e, memInfo, false);
            setData([memInfo, ...usersData]);
        }
        setshowNMember(false);
        handleform()
    };

    function addMember(e, member, isdded) {
        try {
            e.preventDefault();
            console.log(isdded);
            let ischecked = isdded ? false : true;
            console.log("ischecked :: " + ischecked);
            if (ischecked) {
                console.log(selectedMembers);
                setselectedMembers([...selectedMembers, member]);
            } else {
                console.log(selectedMembers);
                const nusers = selectedMembers.filter(
                    (item) => item._id !== member._id
                );
                setselectedMembers(nusers);
            }
        } catch (e) {
            console.log("Error ::::::::::;; " + e);
        }
    }

    const handleScroll = () => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

            if (scrollTop + clientHeight >= scrollHeight - 200 && !loading) {
                fetchData();
            }
        }
    };

    useEffect(() => {
        const container = containerRef.current;
        container.addEventListener("scroll", handleScroll);
        return () => {
            container.removeEventListener("scroll", handleScroll);
        };
    }, [loading]);

    const [typingTimeout, setTypingTimeout] = useState(0);
    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchQuery(value);
        tpageRef.current = 0; // Reset page count
        setData([]); // Clear previous data
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
    };
    useEffect(() => {
        searchQueryRef.current = searchQuery
        setTypingTimeout(
            setTimeout(() => {
                // tpage = 0;
                tpageRef.current = 0; // Reset page count for new search
                fetchData();
                if (searchQuery != "") {
                    setData([]);
                }
            }, 500))
    }, [searchQuery])

    async function fetchData() {
        if (localStorage.getItem("loggedUserInfo")) {
            console.log("Chintuuuuuuuuuuuuu...", searchQuery, searchQueryRef.current?.trim());
            const search = searchQueryRef.current?.trim() || "";

            const currentPage = tpageRef.current + 1;
            tpageRef.current = currentPage;

            const update = {
                page: currentPage,
                search: search,
            }

            const response = await getFormMembersLazy(
                JSON.parse(localStorage.getItem("loggedUserInfo")).gymid,
                update
            );
            console.log(update);
            if (!response) {
                return;
            }
            if (response === "false") {
                setApiError(true)
            } else {
                const usersData = await response.json();
                console.log(usersData, "2234");
                // setPage(page + 1);

                setData((prevData) => {
                    const existingIds = new Set(prevData.map((user) => user._id));
                    const uniqueUsers = usersData.filter((user) => !existingIds.has(user._id));
                    const sortedData = [...prevData, ...uniqueUsers].sort((a, b) => b.mid - a.mid);
                    console.log(sortedData.length)
                    return sortedData;
                });
                setLoading(false);
            }
        }
    }
    useEffect(() => {
        if (props.alreadyadded && props.alreadyadded.length > 0) {
            setselectedMembers(props.alreadyadded);
        } else {
            setselectedMembers([]);
        }
    }, [props.alreadyadded])
    function getExpiryDate(data) {
        try {
            let startdate = new Date();
            let enddate = data.trans[0].expirydate;

            let expirydate = new Date(enddate).getTime() - startdate.getTime();
            let cd = 24 * 60 * 60 * 1000;
            let exdays = Math.floor(expirydate / cd);

            return exdays >= 1
                ? exdays + " Day(s)"
                : data.attendance[0] &&
                    new Date(data.attendance[0].intime).getTime() >=
                    new Date(enddate).getTime()
                    ? "Expired (Overdue)"
                    : "Expired";
        } catch (e) { }
    }
    function getAddonExpiryDate(data) {
        let startdate = new Date();
        let enddate = data.expirydate;

        let expirydate = new Date(enddate).getTime() - startdate.getTime();
        let cd = 24 * 60 * 60 * 1000;
        let exdays = Math.floor(expirydate / cd);
        return exdays >= 1
            ? exdays + " Day(s)"
            : data.attendance[0] &&
                new Date(data.attendance[0].intime).getTime() >=
                new Date(enddate).getTime()
                ? "Expired (Overdue)"
                : "Expired";
    }
    let onadduser = (e) => {
        onDone(e);
    };
    let onDone = (e) => {
        console.log("on Done...");
        // let updates = {};
        let terror = {};

        if (selectedMembers === "") {
            terror.nousers = "No member selected";
            setError(terror);
        }

        if (selectedMembers === "") {
            console.log("Please ");
        }
        selectedMembers.forEach((obj) => {
            console.log(obj);
        
        });
        // localStorage.setItem("page", 1);
        props.onAdd(selectedMembers);
    };

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            // Custom logic to handle the refresh
            // Display a confirmation message or perform necessary actions
        };
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);
    console.log("--------------------------------------------");
    useEffect(() => {
        searchRef.current.focus();
    }, []);
    // useEffect(() => {
    //     searchQueryRef.current = searchQuery;
    // }, [searchQuery]);
    useEffect(() => {
        fetchData();
        // return () => {
        //     setPage(1);
        // };
    }, []);


    // console.log(filterusersData);
    const usersData = userData
    // console.log(usersData);
    // console.log("--------------------------------------------");

    // console.log(selectedMembers);


    const handleRemoveMember = (member) => {
        // Remove the member from selectedMembers
        const updatedMembers = selectedMembers.filter((m) => m._id !== member._id);
        setselectedMembers(updatedMembers);
    };


    const MemberDetails = ({ member }) => (
        <>
            <MDBChip closeIcon onDelete={() => handleRemoveMember(member)}>
                {member.fname} {member.lname}
            </MDBChip>
        </>
    );
    const handleReload = () => {
        window.location.reload();
    }

    return (
        <>
            {apiError ? <PageError handleReload={handleReload} /> : <div className="gym-main p-0 rightContent">
                {!showNMember && (
                    <>
                        <div
                            className="gym-container"
                            id="showcase"
                        >

                            <h6
                                className="gym-xlarge gym-text-red addmembersearch"
                            >
                                {error.nousers && (
                                    <>
                                        <p className="clr-red" style={{ textAlign: "center" }}>
                                            {error.nousers}
                                        </p>
                                    </>
                                )}
                                <input
                                    type="text"
                                    ref={searchRef}
                                    className="gym-input forminput"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                                <img
                                    src={srcImg}
                                    width="20px"
                                    className="searchicon"
                                    alt="Search"
                                />
                            </h6>
                        </div>
                   
                        <div className="d-flex flex-wrap mb-2">
                            {selectedMembers.map(member => (
                                <MemberDetails key={member._id} member={member} />
                            ))}
                        </div>


                        {loading && (
                            <h4>
                                <Loader />
                            </h4>
                        )}
                        <div className="mainbox dynscrolll" ref={containerRef}>
                            <div className="mainboxsecond mt-12">
                                {!loading && (
                                    <>
                                        {usersData &&
                                            usersData.length > 0 &&
                                            usersData.map((data, index) => (!data.issuspend &&
                                                <>
                                                    <a
                                                        href="#"
                                                        className="ml20 pd_0"
                                                        style={{ width: "47%" }}
                                                        onClick={(e) =>
                                                            addMember(
                                                                e,
                                                                data,
                                                                selectedMembers.filter(
                                                                    (item) => item._id === data._id
                                                                )[0]
                                                            )
                                                        }
                                                    >
                                                        <div
                                                            className="row usercar-prev"
                                                            onClick={(e) =>
                                                                addMember(
                                                                    e,
                                                                    data,
                                                                    selectedMembers.filter(
                                                                        (item) => item._id === data._id
                                                                    )[0]
                                                                )
                                                            }
                                                        >
                                                            <div className="d-flex space-between">
                                                                <div className="add-numbr-clr">
                                                                    <h6 className="mb-1">
                                                                        {data.fname} {data.lname}
                                                                    </h6>
                                                                    <div>
                                                                        {"Mid: "}{data.mid}{", "}{data.sex ? data.sex : data.gender}{" "}
                                                                        {(data.sex || data.gender) &&
                                                                            (data.age || data.weight || data.number)
                                                                            ? ", "
                                                                            : ""}
                                                                        {data.age}
                                                                        {data.age ? "y" : ""}{" "}
                                                                        {data.age && (data.weight || data.number)
                                                                            ? ", "
                                                                            : ""}{" "}
                                                                        {data.weight}
                                                                        {data.weight ? "kgs " : ""}
                                                                        {data.weight && data.number ? "," : ""}
                                                                        {data.number}
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    <div
                                                                        style={{ zIndex: 2 }}
                                                                        className="user-prev-details"
                                                                        onClick={(e) =>
                                                                            addMember(
                                                                                e,
                                                                                data,
                                                                                selectedMembers.filter(
                                                                                    (item) => item._id === data._id
                                                                                )[0]
                                                                            )
                                                                        }
                                                                    >
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={
                                                                                selectedMembers.filter(
                                                                                    (item) => item._id === data._id
                                                                                ).length > 0
                                                                            }
                                                                            id={"checkbox_useradd_" + index}
                                                                        />
                                                                        <label
                                                                            for={"checkbox_useradd_" + index}
                                                                        ></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="pt-1 clr-black">
                                                                <span className="add-mbrs-span">Plan: </span>
                                                                {data.plan && data.plan.length > 0
                                                                    ? data.plan[0].name +
                                                                    `, Expires in: ${getExpiryDate(data)}`
                                                                    : "No Plan"}{" "}
                                                                <br />
                                                                <span className="add-mbrs-span">
                                                                    {" "}
                                                                    Addons:
                                                                </span>{" "}
                                                                {data.addons && data.addons.length > 0
                                                                    ? data.addons[0].name +
                                                                    `, Expires in: ${getAddonExpiryDate(data)}`
                                                                    : "No Addon(s)"}{" "}
                                                            </div>
                                                        </div>
                                                    </a>
                                                </>
                                            ))}
                                        {usersData && usersData.length <= 0 && (
                                            <>
                                                <div>No members found</div>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        {props.showcheck && (
                            <>
                                <div className="d-flex align-center mb-1">
                                    {" "}
                                    <input type="checkbox"></input>
                                    <span className="pl-4">
                                        {" "}
                                        Add the addon(s) in this transaction to this user.
                                    </span>
                                </div>
                                <br />
                            </>
                        )}
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-mdb-dismiss="modal"
                                onClick={showNewMember}
                            >
                                Add New Member
                            </button>
                            &nbsp;&nbsp;
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-mdb-dismiss="modal"
                                onClick={onDone}
                            >
                                Done
                            </button>
                        </div>
                    </>
                )}

               
                {showNMember && (
                    <>
                        <CreateMember
                            id={props.id}
                            index={props.index}
                            onsubmit={onadduser}
                            submit={props.submit}
                            backallback={hideCreateMember}
                        />
                    </>
                )}
            </div>}
        </>
    );
};

export default AddformMembers