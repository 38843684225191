import { format, parseISO } from "date-fns";
import {
  MDBDatatable,
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import React, { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import ReactGA from "react-ga";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router";
import Header from "../components/Header";
import LeftPane from "../components/LeftPane";
import {
  DBActivMembers,
  DBCheckins,
  DBExpirymemArray,
  DBMembershipRenewal,
  DBPayment,
  DBStaffattendance,
  getGymAddonsList,
  getPlans,
} from "../services/apiServices.js";
import payRImg from "./../components/images/Payments-received.svg";
import calImg from "./../components/images/calendar.svg";
import greenImg from "./../components/images/green.svg";
import payPImg from "./../components/images/payments-pending.svg";
import redcImg from "./../components/images/red-c.svg";
import stdwnImg from "./../components/images/stats-down.svg";
import stupImg from "./../components/images/stats-up.svg";
import ucheckInImg from "./../components/images/user-checkin.svg";
import usrImg from "./../components/images/users.svg";
import PageError from "./PageError";
import { isMobile } from "react-device-detect";

export default function Dashboard() {

  const [apiError, setApiError] = useState(false);
  const [basicActive, setBasicActive] = useState("tab1");
  const [addons, setAddons] = useState([]);
  const [plans, setPlans] = useState([]);

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };
  const navigate = useNavigate();

  const [sFlag, setFlag] = useState(0);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [expiryData, setExpiryData] = useState([])
  const [startDate, endDate] = dateRange;
  const [cusomck, setCusomck] = useState(false);
  const [checked, setChecked] = useState((localStorage.getItem("loggedUserInfo")) && JSON.parse(localStorage.getItem("loggedUserInfo")).role === "admin" ? "Month" : "Today");
  const [StaffAtt, setStaffAtt] = useState("");
  const [MemRenew, setMemRenew] = useState("");
  const [Checkin, setCheckin] = useState("");
  const [ActMem, setActMem] = useState("");
  const [Payment, setPayment] = useState("");
  let gymid = null;
  try {
    gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
  } catch (e) {
    localStorage.removeItem("token");
    localStorage.removeItem("loggedUserInfo");
    window.location.href = "/login";

  }

  function getDateRange(value) {
    console.log(value);
    setDateRange(value);
    if (value && value.length === 2 && value[1] !== null) {
      let fdate = getRDateTime(value[0]).date;
      let tdate = getRDateTime(value[1]).date;
      console.log(fdate + ", " + tdate);
      fetchData(fdate, tdate);
    }
  }
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);


  function getRDateTime(tdbtime) {
    var now = new Date(tdbtime);
    var year = now.getFullYear();
    var month = now.getMonth() + 1;
    var day = now.getDate();
    var hour = now.getHours();
    var minute = now.getMinutes();
    var second = now.getSeconds();
    if (month.toString().length === 1) {
      month = "0" + month;
    }
    if (day.toString().length === 1) {
      day = "0" + day;
    }
    if (hour.toString().length === 1) {
      hour = "0" + hour;
    }
    if (minute.toString().length === 1) {
      minute = "0" + minute;
    }
    if (second.toString().length === 1) {
      second = "0" + second;
    }
    var dateTime = {
      date: year + "-" + month + "-" + day,
      time: hour + ":" + minute,
    };
    return dateTime;
  }

  const CalenderInput = forwardRef(({ value, onClick }, ref) => (
    <>
      <span className="gym-input forminput " onClick={onClick}>
        {value === "" ? "Custom Range" : value}
        &nbsp;
        <img src={calImg} alt="Custom Range" ref={ref} title="Select Custom Range" />
      </span>
    </>
  ));
  function fetchData(fDay, lDay) {
    setDateRange([new Date(fDay), new Date(lDay)])
    let firstoftheday = new Date(fDay);
    firstoftheday.setHours(0, 0, 0, 0);
    const firstDay = firstoftheday.toISOString();
    let lastoftheday = new Date(lDay);
    lastoftheday.setHours(23, 59, 59, 999);
    const lastDay = lastoftheday.toISOString();
    ChangingData();
    paymentFunction(firstDay, lastDay);
    activememberFunction(firstDay, lastDay);

    StaffattendanceFunction(firstDay, lastDay);
    memberRenewalFunction(firstDay, lastDay);

    checkinsFunction(firstDay, lastDay);
  }
  function ChangingData() {
    setStaffAtt("");

    setMemRenew("");

    setCheckin("");
    setActMem("");
    setPayment("");
  }
  //****************************************************************************** */
  function handleTodayClick(id) {
    setCusomck(false);
    setChecked(id);
    const currentTime = new Date();
    const startOfToday = new Date(currentTime);
    //startOfToday.setHours(0, 0, 0, 0);

    const endOfToday = new Date(currentTime);
    //endOfToday.setHours(23, 59, 59, 999);
    fetchData(startOfToday, endOfToday);
  }

  function handleYesterdayClick(id) {
    setChecked(id);
    setCusomck(false);
    const currentTime = new Date();
    const yesterday = new Date(currentTime);
    yesterday.setDate(currentTime.getDate() - 1);
    const startOfYesterday = new Date(yesterday);
    //startOfYesterday.setHours(0, 0, 0, 0);
    const endOfYesterday = new Date(yesterday);
    //endOfYesterday.setHours(23, 59, 59, 999);
    fetchData(startOfYesterday, endOfYesterday);
  }

  function handleThisWeekClick(id) {
    setChecked(id);
    setCusomck(false);
    const currentTime = new Date();
    const startOfWeek = new Date(currentTime);
    startOfWeek.setDate(currentTime.getDate() - currentTime.getDay());
    startOfWeek.setHours(12, 0, 0, 0);
    const endOfWeek = new Date(currentTime);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(12, 0, 0, 0);
    fetchData(startOfWeek, endOfWeek);
  }

  function handleThisMonthClick(id) {
    setChecked(id);
    setCusomck(false);
    const currentTime = new Date();
    const startOfMonth = new Date(currentTime);
    startOfMonth.setDate(1);
    //startOfMonth.setHours(0, 0, 0, 0);

    const endOfMonth = new Date(currentTime);
    endOfMonth.setMonth(currentTime.getMonth() + 1, 0);
    //endOfMonth.setHours(23, 59, 59, 999);
    fetchData(startOfMonth, endOfMonth);
  }

  function handleThisCustomClick(id) {
    setChecked(id);
    setCusomck(true);
  }
  async function fetchPData() {
    if (localStorage.getItem("loggedUserInfo")) {
      const response = await getPlans(
        JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
      );
      if (!response) {
        return;
      }
      if (response === "false") {
        setApiError(true)
      } else {
        const plans = await response.json();
        console.log(plans);
        setPlans(plans);
      }
    }
  }

  async function fetchAddons() {
    if (localStorage.getItem("loggedUserInfo")) {
      const response = await getGymAddonsList(
        JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
      );
      if (!response) {
        return;
      }
      if (response === "false") {
        setApiError(true)
      } else {
        const data = await response.json()
        setAddons(data);
        console.log("Info set..", data);
      }
    }
  }
  /*********************by vamshi************************************** */

  useEffect(() => {
    // expiryarrayFunction()
    fetchAddons();
    fetchPData();

    let todaydate = new Date();

    var firstDay = new Date(todaydate);
    firstDay.setDate(1);
    firstDay.setHours(12, 0, 0, 0);
    





    var lastDay = new Date(todaydate);
    lastDay.setMonth(todaydate.getMonth() + 1, 0);
 
    if((localStorage.getItem("loggedUserInfo")) && JSON.parse(localStorage.getItem("loggedUserInfo")).role === "admin"){
      setDateRange([firstDay, lastDay]);
      fetchData(firstDay, lastDay);
    }else{
      const currentTime = new Date();
      const startOfToday = new Date(currentTime);
      const endOfToday = new Date(currentTime);
      fetchData(startOfToday, endOfToday);
  
    }

  }, [sFlag]);

  async function StaffattendanceFunction(firstDay, lastDay) {
    const response = await DBStaffattendance(gymid, {
      fdate: firstDay,
      tdate: lastDay,
      today: new Date()
    });
    if (!response) {
      return;
    }
    if (response === "false") {
      setApiError(true)
    } else {
      const recinfo = await response.json();
      setStaffAtt(recinfo);
      // console.log(recinfo);
    }
  }
  async function memberRenewalFunction(firstDay, lastDay) {
    const response = await DBMembershipRenewal(gymid, {
      fdate: firstDay,
      tdate: lastDay,
      today: new Date()
    });
    if (!response) {
      return;
    }
    if (response === "false") {
      setApiError(true)
    } else {
      const recinfo = await response.json();
      // console.log(recinfo);
      setMemRenew(recinfo);
    }
  }

  async function checkinsFunction(firstDay, lastDay) {
    const response = await DBCheckins(gymid, {
      fdate: firstDay,
      tdate: lastDay,
      today: new Date()
    });
    if (!response) {
      return;
    }
    if (response === "false") {
      setApiError(true)
    } else {
      const recinfo = await response.json();
      setCheckin(recinfo);
    }
  }

  async function activememberFunction(firstDay, lastDay) {
    const response = await DBActivMembers(gymid, {
      fdate: firstDay,
      tdate: lastDay,
      today: new Date()
    });
    if (!response) {
      return;
    }
    if (response === "false") {
      setApiError(true)
    } else {
      const recinfo = await response.json();
      setActMem(recinfo);
    }
  }
  async function paymentFunction(firstDay, lastDay) {
    const response = await DBPayment(gymid, {
      fdate: firstDay,
      tdate: lastDay,
      today: new Date()
    });
    if (!response) {
      return;
    }
    if (response === "false") {
      setApiError(true)
    } else {
      const recinfo = await response.json();
      setPayment(recinfo);
      console.log(recinfo);
    }
  }

  useEffect(() => {
    fetchExpiryData()
  }, [])

  async function fetchExpiryData() {
    const response = await DBExpirymemArray(gymid);
    console.log("huwidgucddfgeh", response);
    if (!response) {
      return;
    }
    if (response === "false") {
      setApiError(true)
    } else {
      const recinfo = await response.json();
      console.log(recinfo);
      setExpiryData(recinfo)

      const response1 = await getPlans(
        localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : ""
      );
      if (!response1) {
        return;
      }
      if (response1 === "false") {
        setApiError(true)
        return;
      }

      setPlans(await response1.json());
      const response2 = await getGymAddonsList(
        localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : ""
      );
      if (!response2) {
        return;
      }
      if (response2 === "false") {
        setApiError(true)
        return;
      }
      setAddons(await response2.json())

    }
  }

  let overdmem = [];
  let exdmem = [];

  let plansInfo = localStorage.getItem("planInfo") ? JSON.parse(localStorage.getItem("planInfo")) : null;

  expiryData && expiryData.length > 0 && expiryData.forEach((data, index) => {
    let rarray = [];

    // rarray.push(data.fname + " " + data.lname);

    let filteredplandata = plans.length > 0 ? plans.find((item) => item._id === data.planid) : [];
    const filteredaddondata = addons.length > 0 ? addons.find((item) => item._id === data.planid) : [];

    if (plansInfo === null || (plansInfo.planFeature && plansInfo.planFeature.planswithsessionslimit)) {
      // Don't filter, use the original filteredplandata
    } else {
      // Filter out plans with sessioncheck set to true
      let filterPlans = plans.filter((obj) => !obj.sessioncheck);
      filteredplandata = filterPlans.find((item) => item._id === data.planid);
    }
    // if (filteredplandata?.name) {
    rarray.push(data.fname + " " + data.lname);
    rarray.push(filteredplandata?.name || filteredaddondata?.name || "--");
    rarray.push(data.number ? data.number : "--");
    rarray.push(data.sex ? data.sex : "--");
    rarray.push(data.age ? data.age : '--');
    rarray.push(format(parseISO(data.expirydate), "yyyy-MM-dd"));
    rarray.push(!data.issuspend &&
      <>
        <div
          className="ml0 link-color"
          onClick={(e) =>
            preaddUser({
              member: data, planId: data.planid
            })
          }
        >
          Renew
        </div>
      </>
    );
    // }
    //by vamshi
    /**************************************************************************************************************/
    var week = new Date();
    week.setDate(week.getDate() + 6);
    const exp = new Date(data.expirydate);

    if (exp < new Date(week) && exp >= new Date()) {
      overdmem.push(rarray);
    }
    if (exp < new Date()) {
      exdmem.push(rarray);
    }
  }
  );

  const mData = {
    columns: [
      "Name", "Plan/Addon", "Contact", "Gender", "Age", "Expired On", "Actions"
    ],
    rows: overdmem,
  };
  const mOData = {
    columns: [
      "Name", "Plan/Addon", "Contact", "Gender", "Age", "Expired On", "Actions"
    ],
    rows: exdmem,
  };



  function preaddUser(dataObj) {
    localStorage.setItem("preadd_plan", JSON.stringify(dataObj));
    localStorage.setItem("preadd_member", JSON.stringify(dataObj));
    navigate("/home");
  }

  const showInfo = (event, page, tInfo) => {
    const Dayfilter = checked === "Today" ? "1" : (checked === "Yesterday" ? "2" : (checked === "Week" ? "3" : (checked === "Month" ? "4" : (checked === "Custom" ? "6" : "7"))));
    if (event.ctrlKey || event.metaKey) {
      event.preventDefault();

      if (window.opener === null) {
        window.open(`/${page}`, "_blank");
      } else {
        navigate("/" + page, (page === "transactions" ? {

          state: {
            ...tInfo, fdate: new Date(startDate), tdate: new Date(endDate), Dayfilter: Dayfilter
          },
        } : {

          state: { ...tInfo, fdate: new Date(startDate), tdate: new Date(endDate) },
        }));
      }
    } else {
      navigate(`/${page}`);
      navigate("/" + page, (page === "transactions" ? {

        state: {
          ...tInfo, fdate: new Date(startDate), tdate: new Date(endDate), Dayfilter: Dayfilter
        },
      } : {

        state: { ...tInfo, fdate: new Date(startDate), tdate: new Date(endDate) },
      }));
    }
  };

  const handleReload = () => {
    window.location.reload();
  }
  return (
    <>
      <LeftPane isactive="11" />
      <Header />
      {apiError ? <PageError handleReload={handleReload} /> : <div className="gym-main gym-main-div rightContent">
        <div className="gym-container gym-cnr" id="showcase">
          <h3 className="gym-large">Dashboard</h3>

          <div className="d-flex dashboard_links d-flex align-items-center">
            <button
              className={`p-0 me-5 button-link ${checked === "Today" ? "active" : ""}`}
              onClick={() => handleTodayClick("Today")}
            >
              Today
            </button>
            {(localStorage.getItem("loggedUserInfo")) && JSON.parse(localStorage.getItem("loggedUserInfo")).role === "admin" && <button
              className={`p-0 me-5 button-link ${checked === "Yesterday" ? "active" : ""}`}
              onClick={() => handleYesterdayClick("Yesterday")}
            >
              Yesterday
            </button>}
            {(localStorage.getItem("loggedUserInfo")) && JSON.parse(localStorage.getItem("loggedUserInfo")).role === "admin" && <button
              className={`p-0 me-5 button-link ${checked === "Week" ? "active" : ""}`}
              onClick={() => handleThisWeekClick("Week")}
            >
              {" "}
              This Week
            </button>}
            {(localStorage.getItem("loggedUserInfo")) && JSON.parse(localStorage.getItem("loggedUserInfo")).role === "admin" && <button
              className={`p-0 me-5 button-link ${checked === "Month" ? "active" : ""}`}
              onClick={() => handleThisMonthClick("Month")}
            >
              This Month
            </button>}
            {(localStorage.getItem("loggedUserInfo")) && JSON.parse(localStorage.getItem("loggedUserInfo")).role === "admin" && <span className="customRange align-items-center">
              <button
                className={`p-0 me-5 custom button-link ${checked === "Custom" ? "active" : ""}`}
                onClick={() => handleThisCustomClick("Custom")}
              >
                Custom Range
              </button>
              {cusomck ? (
                <div className="gym-xlarge gym-larg-pos">
                  <DatePicker
                    dateFormat={"d/M/yyyy"}
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                      getDateRange(update);
                    }}
                    isClearable={false}
                    customInput={<CalenderInput />}
                  />
                </div>
              ) : null}
            </span>}
          </div>
          <p className="pt-3 m-0 dashboard_note">
            Note: Above filters will show you the comparison of the data based
            on the selection. Ex: Week selection will show selected week and its
            previous last week data
          </p>
        </div>
        <div className={`row mt-10 ${isMobile ? "m-1" : ""}`} >


          <div className="col-xl-3 col-sm-12 col-md-6 col-6">
            <div className="card mb-0 card-gym card-h">
              <div className=" g-0 ">
                <div className="d-flex space-between p-15 col-lg-12">
                  <h5 className="card-title ">Today's Check-In's</h5>
                </div>
                <div className="d-flex">
                  <div className="col-md-9 col-sm-9 w-50">
                    <div className="card-body card-body-dashb">
                      <h5 className="card-text clr-main d-flex align-center">
                        <button className="button-link link-color"
                          onClick={(e) => showInfo(e, "checkin", { data: 0 })}
                        >
                          {Checkin !== ""
                            ? Checkin.todaycheckin
                            : <Skeleton className="w-30px" /> || 0}
                        </button>
                      </h5>
                      <div className="card-text d-flex space-between">
                        <small>Male </small>
                        <small>
                          <button className="button-link link-color"
                            onClick={(e) => showInfo(e, "checkin", { data: 1 })}
                          >
                            {Checkin !== ""
                              ? Checkin.mcount
                              : <Skeleton className="w-30px" /> || 0}
                          </button>
                        </small>
                      </div>
                      <div className="card-text d-flex space-between">
                        <small>Female </small>
                        <small>
                          <button className="button-link link-color"
                            onClick={(e) => showInfo(e, "checkin", { data: 2 })}
                          >
                            {Checkin !== ""
                              ? Checkin.fcount
                              : <Skeleton className="w-30px" /> || 0}
                          </button>
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3 d-flex justify-end w-48">
                    <img
                      className="h-103"
                      src={ucheckInImg}
                      alt="Trendy Pants and Shoes"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-12 col-md-6 col-6">
            <div className="card mb-0 card-gym card-h">
              <div className=" g-0 ">
                <div className="d-flex space-between p-15 col-lg-12">
                  <h5 className="card-title ">Active Members</h5>
                  <div className="d-flex align-items-center justify-content-center">
                    {ActMem === "" ? (
                      <Skeleton className="w-30px" />
                    ) : (
                      <>
                        <img
                          src={ActMem.upflag === "green" ? stupImg : stdwnImg}
                          alt="green"
                          className="me-2"
                        />
                        {ActMem.percent === "Infinity"
                          ? "100%"
                          : isNaN(ActMem.percent)
                            ? 0 + "%"
                            : ActMem.percent + "%"}
                      </>
                    )}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="col-md-9 col-sm-9 w-50">
                    <div className="card-body card-body-dashb">
                      <h5 className="card-text clr-main d-flex align-center">
                        <button className="button-link link-color"
                          onClick={(e) => showInfo(e, "mmanager", { data: 10 })}
                        >
                          {ActMem !== ""
                            ? ActMem.active
                            : <Skeleton className="w-30px" /> || 0}
                        </button>
                      </h5>
                      <div className="card-text d-flex space-between">
                        <small>Male </small>
                        <small>
                          <button className="button-link link-color"
                            onClick={(e) =>
                              showInfo(e, "mmanager", { data: 11 })
                            }
                          >
                            {ActMem !== ""
                              ? ActMem.mactive
                              : <Skeleton className="w-30px" /> || 0}
                          </button>
                        </small>
                      </div>
                      <div className="card-text d-flex space-between">
                        <small>Female </small>
                        <small>
                          <button className="button-link link-color"
                            onClick={(e) =>
                              showInfo(e, "mmanager", { data: 12 })
                            }
                          >
                            {ActMem !== ""
                              ? ActMem.factive
                              : <Skeleton className="w-30px" /> || 0}
                          </button>
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3 d-flex justify-end w-48">
                    <img
                      className="h-103"
                      src={usrImg}
                      alt="Trendy Pants and Shoes"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-12 col-md-6 col-6">
            <div className="card mb-0 card-gym card-h">
              <div className=" g-0 ">
                <div className="d-flex space-between p-15 col-lg-12">
                  <h5 className="card-title ">Payment Received</h5>
                  <div className="d-flex align-items-center justify-content-center">
                    {Payment === "" ? (
                      <Skeleton className="w-30px" />
                    ) : (
                      <>
                        <img
                          src={Payment.prflag === "green" ? stupImg : stdwnImg}
                          alt=""
                          className="me-2"
                        />
                        {Payment.prpercent === "Infinity"
                          ? "100%"
                          : isNaN(Payment.prpercent)
                            ? 0 + "%"
                            : Payment.prpercent + "%"}
                      </>
                    )}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="col-md-9 col-sm-9 w-50">
                    <div className="card-body card-body-dashb">
                      <h5 className="card-text clr-main-green d-flex align-center">
                        <img className="paymt-rec" src={greenImg} alt="" />
                        <button className="button-link link-color"
                          onClick={(e) =>
                            showInfo(e, "transactions", { data: 0 })
                          }
                        >

                          {Payment !== "" ? parseFloat(Payment.received).toFixed(2) : <Skeleton className="w-30px" /> || 0}
                        </button>
                      </h5>

                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3 d-flex justify-end w-48">
                    <img
                      className="h-103"
                      src={payRImg}
                      alt="Trendy Pants and Shoes"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-12 col-md-6 col-6">
            <div className="card mb-0 card-gym card-h ">
              <div className=" g-0 ">
                <div className="d-flex space-between p-15 col-lg-12">
                  <h5 className="card-title ">Payment Pending</h5>
                  <div className="d-flex align-items-center justify-content-center">
                    {Payment === "" ? (
                      <Skeleton className="w-30px" />
                    ) : (
                      <>
                        <img
                          src={Payment.peflag === "green" ? stupImg : stdwnImg}
                          alt="green"
                          className="me-2"
                        />
                        {Payment.pepercent === "Infinity"
                          ? "100%"
                          : isNaN(Payment.pepercent)
                            ? 0 + "%"
                            : Payment.pepercent + "%"}
                      </>
                    )}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="col-md-9 col-sm-9 w-50">
                    <div className="card-body card-body-dashb">
                      <h5 className="card-text clr-main-red d-flex align-center">
                        <img className="paymt-rec" src={redcImg} alt="" />
                        <button className="button-link link-color"
                          onClick={(e) =>
                            showInfo(e, "transactions", { data: 3 })
                          }
                        >
                          {Payment !== "" ? parseFloat(Payment.pending).toFixed(2) : <Skeleton className="w-30px" /> || 0}
                        </button>
                      </h5>

                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3 d-flex justify-end w-48">
                    <img
                      className="h-103"
                      src={payPImg}
                      alt="Trendy Pants and Shoes"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-sm-12 col-md-6 col-6">
            <div className="card mb-0 card-gym card-h">
              <div className=" g-0 ">
                <div className="d-flex space-between p-15 col-lg-12">
                  <h5 className="card-title ">Plan Renewals</h5>
                  <div className="d-flex align-items-center justify-content-center">
                    {MemRenew === "" ? (
                      <Skeleton className="w-30px" />
                    ) : (
                      <>
                        <img
                          src={MemRenew.upflag === "green" ? stupImg : stdwnImg}
                          alt=""
                          className="me-2"
                        />
                        {MemRenew.percent === "Infinity"
                          ? "100%"
                          : isNaN(MemRenew.percent)
                            ? 0 + "%"
                            : MemRenew.percent + "%"}
                      </>
                    )}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="col-md-9 col-sm-9 w-50">
                    <div className="card-body card-body-dashb">
                      <h5 className="card-text clr-main">
                        <button className="button-link link-color"
                          onClick={(e) => showInfo(e, "mmanager", { data: 6 })}
                        >
                          {MemRenew !== "" ? MemRenew.renewcount
                            : <Skeleton className="w-30px" /> || 0}
                        </button>
                      </h5>
                      <div className="card-text d-flex space-between">
                        <small>Extended </small>
                        <small>
                          <button className="button-link link-color"
                            onClick={(e) =>
                              showInfo(e, "mmanager", { data: 7 })
                            }
                          >
                            {MemRenew !== ""
                              ? MemRenew.extended
                              : <Skeleton className="w-30px" /> || 0}
                          </button>
                        </small>
                      </div>
                      <div className="card-text d-flex space-between">
                        <small>Upgraded </small>
                        <small>
                          <button className="button-link link-color"
                            onClick={(e) =>
                              showInfo(e, "mmanager", { data: 8 })
                            }
                          >
                            {MemRenew !== ""
                              ? MemRenew.urenewcount
                              : <Skeleton className="w-30px" /> || 0}
                          </button>
                        </small>
                      </div>
                      <div className="card-text d-flex space-between">
                        <small>Downgraded </small>
                        <small>
                          <button className="button-link link-color"
                            onClick={(e) =>
                              showInfo(e, "mmanager", { data: 9 })
                            }
                          >
                            {MemRenew !== ""
                              ? MemRenew.drenewcount
                              : <Skeleton className="w-30px" /> || 0}
                          </button>
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3 d-flex justify-end w-48">
                    <img
                      className="h-103"
                      src={usrImg}
                      alt="Trendy Pants and Shoes"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-12 col-md-6 col-6">
            <div className="card mb-0 card-gym card-h">
              <div className=" g-0 ">
                <div className="d-flex space-between p-15 col-lg-12">
                  <h5 className="card-title ">Today's Staff Attendance</h5>
                </div>
                <div className="d-flex">
                  <div className="col-md-9 col-sm-9 w-50">
                    <div className="card-body card-body-dashb">
                      <h5 className="card-text clr-main d-flex align-center">
                        <button className="button-link link-color"
                          onClick={(e) => showInfo(e, "staff", { data: 0 })}
                        >
                          {StaffAtt !== ""
                            ? StaffAtt.count
                            : <Skeleton className="w-30px" /> || 0}
                        </button>
                      </h5>
                      <div className="card-text d-flex space-between">
                        <small>Present</small>
                        <small>
                          <button className="button-link link-color"
                            onClick={(e) => showInfo(e, "staff", { data: 1 })}
                          >
                            {StaffAtt !== ""
                              ? StaffAtt.present
                              : <Skeleton className="w-30px" /> || 0}
                          </button>
                        </small>
                      </div>
                      <div className="card-text d-flex space-between clr-red">
                        <small className="">Absent </small>
                        <small className="">
                          <button className="button-link link-color"
                            onClick={(e) => showInfo(e, "staff", { data: 2 })}
                          >
                            {StaffAtt !== ""
                              ? StaffAtt.absent
                              : <Skeleton className="w-30px" /> || 0}
                          </button>
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3 d-flex justify-end w-48">
                    <img
                      className="h-103"
                      src={usrImg}
                      alt="Trendy Pants and Shoes"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <MDBTabs className="mt-3 mb-2">
          <MDBTabsItem>
            <MDBTabsLink
              onClick={() => handleBasicClick("tab1")}
              active={basicActive === "tab1"}
            >
              Expiring This Week
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink
              onClick={() => handleBasicClick("tab2")}
              active={basicActive === "tab2"}
            >
              Expired in last 30 days
            </MDBTabsLink>
          </MDBTabsItem>

        </MDBTabs>
        <MDBTabsContent>
          <MDBTabsPane show={basicActive === "tab1"} open={basicActive === "tab1"}>
            <MDBDatatable
              fixedHeader
              maxHeight="500px"
              data={mData}
              defaultentries={5}
            />
          </MDBTabsPane>
          <MDBTabsPane show={basicActive === "tab2"} open={basicActive === "tab2"}>
            <MDBDatatable
              fixedHeader
              maxHeight="500px"
              data={mOData}
              defaultentries={5}
            />
          </MDBTabsPane>
        </MDBTabsContent>
      </div>}
    </>
  );
}