import React, { useEffect, useState } from "react";
import logodark from "../motos_lp/assets/images/Easy-MembrLogo.png";
import { NavbarBrand, } from "reactstrap";
import { Link } from "react-router-dom";
import { MDBBtn, MDBIcon } from "mdb-react-ui-kit";
function Terms() {
  const [imageLoaded, setimageLoaded] = useState(true);
  const [logourl, setlogourl] = useState(localStorage.getItem("loggedUserInfo") ? (JSON.parse(localStorage.getItem("loggedUserInfo")).gymlogoUrl || logodark) : logodark);

  return (
    <>
      <div className="mb-10">
        <nav id="navbar" className="navbar navbar-expand-lg fixed-top sticky align-items-center justify-content-center">


          <NavbarBrand to="/">
            {imageLoaded ? (
              <>
                <div className="d-flex align-items-center position-relative">
                  <img src={logourl} alt="Logo" className="newLogo" onLoad={() => setimageLoaded(true)} onError={() => setimageLoaded(false)} />
                  <div className="text-dark fs-6 ByZencruzText" >By Zencruz</div>
                </div>
              </>
            ) : (
              <>
                <div className="d-flex align-items-center position-relative">
                  <img src={logodark} alt="Default Logo" className="newLogo" />
                  <div className="text-dark fs-6 ByZencruzText" >By Zencruz</div>
                </div>
              </>

            )}
          </NavbarBrand>
          <div className="back-to-home">
            <Link to={-1}>
              <MDBBtn>
                <MDBIcon fas icon="arrow-left" />
              </MDBBtn>
            </Link>
          </div>
        </nav>
      </div>
            <div>
        <h3 className="py-3 container p-14 font">Terms and Conditions:</h3>
                <div className="container p-14 font">

                <section>
                  <h5>Non-Refundable:

                  </h5>
                  <p> 
                  All payments made for the usage of EasyMembr are non-refundable. This means that once the payment is made, it will not be refunded, even in the event of cancellation or termination of the service.
                  </p>
                </section>

                <section>
                <h5>Standard SaaS Software Usage License: </h5><p>By using EasyMembr, you are granted a standard Software-as-a-Service (SaaS) usage license. This license allows you to access and utilize the features and functionalities of the software specifically for your gym administration purposes.

                  </p>
                </section>

                <section>
                <h5>Restrictions:</h5><p>You are strictly prohibited from copying, modifying, distributing, or reverse engineering any part of the EasyMembr software. The license granted is for your personal and non-commercial use only, and you may not transfer or sublicense it to any third party.

                  </p>
                </section>

                <section>
                <h5>Intellectual Property:</h5><p>All intellectual property rights, including copyrights and trademarks, associated with EasyMembr are owned by the respective owners or licensors. You may not use any of these intellectual property rights without prior written permission.

                  </p>
                </section>

                <section>
                <h5>Data Security: </h5><p>EasyMembr takes reasonable measures to ensure the security of your data. However, it is your responsibility to maintain the confidentiality of your login credentials and protect your account from unauthorized access.

                  </p>
                </section>

                <section>
                <h5>Termination: </h5><p>Either party may terminate the usage of EasyMembr at any time. Upon termination, you will no longer have access to the software, and any data associated with your account may be deleted.

                  </p>
                </section>

                <section>
                <h5>Limitation of Liability:</h5><p>EasyMembr and its owners or licensors are not liable for any direct, indirect, incidental, or consequential damages arising from the use or inability to use the software.
                  </p>
                </section>
            </div>
            </div>
    </>
  )
}

export default Terms;
