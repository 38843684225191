import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { contactus, customPremium } from "../../services/apiServices";
import { MDBAlert } from "mdb-react-ui-kit";

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            email: "",
            phone: "",
            inquiry: "",
            errors: {
                firstName: "",
                email: "",
                phone: "",
                inquiry: "",
            },
            alerttxt: "",
            status: ""
        };
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const { firstName, email, phone, inquiry, errors } = this.state;

        // Validate all fields before submission
        if (!this.validateForm()) return;

        const updates = {
            fname: firstName,
            email: email,
            number: phone,
            inquiry: inquiry,
            // from: "leads",
        };

        try {
            const response = await customPremium(updates);
            if (response.status === 200) {
                // Reset the form
                this.setState({
                    firstName: "",
                    email: "",
                    phone: "",
                    inquiry: "",
                    errors: {
                        firstName: "",
                        email: "",
                        phone: "",
                        inquiry: "",
                    },

                });
                this.setState({ alerttxt: "Your inquiry has been submitted successfully!", status: "success" })

            } else {
                this.setState({ alerttxt: "There was an issue submitting your inquiry. Please try again.", status: "danger" })

            }
        } catch (error) {
            console.error("Error submitting form: ", error);
            // alert("An error occurred. Please try again later.");
            this.setState({ alerttxt: "An error occurred. Please try again later.", status: "danger" })
        }
    };

    handleChange = (e) => {
        const { id, value } = e.target;
        this.setState({ [id]: value }, () => this.validateField(id, value));
    };

    validateField = (fieldName, value) => {
        let errors = { ...this.state.errors };

        switch (fieldName) {
            case "firstName":
                errors.firstName = value.trim() === "" ? "First name is required." : "";
                break;
            case "email":
                errors.email = /\S+@\S+\.\S+/.test(value) ? "" : "Enter a valid email address.";
                break;
            case "phone":
                errors.phone =
                    value.trim() === "" || value.length < 10
                        ? "Enter a valid 10-digit phone number."
                        : "";
                break;
            case "inquiry":
                errors.inquiry = value.trim() === "" ? "Inquiry message is required." : "";
                break;
            default:
                break;
        }

        this.setState({ errors });
    };

    validateForm = () => {
        const { firstName, email, phone, inquiry } = this.state;

        // Trigger validation for all fields
        this.validateField("firstName", firstName);
        this.validateField("email", email);
        this.validateField("phone", phone);
        this.validateField("inquiry", inquiry);

        const { errors } = this.state;

        // Check if there are any errors
        return !Object.values(errors).some((error) => error !== "");
    };
    handleralert = () => {
        this.setState({ alerttxt: "", status: "" })
    }

    render() {
        const { firstName, email, phone, inquiry, errors, alerttxt } = this.state;
        return (
            <React.Fragment>
                <section className="" id="contact">
                    <Container>
                        <Row className="justify-content-center">
                            <Col>
                                <div className="section-title text-center pb-2">
                                    <h4 className="title mb-3">Get In Touch!</h4>
                                </div>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col lg={6} md={4} className="order-md-2 order-1 mt-4 pt-2">
                                <div className="p-4 rounded shadow bg-white">
                                    <form
                                        id="contactForm"
                                        className="login-form"
                                        onSubmit={this.handleSubmit}
                                    >
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <label className="form-label">
                                                        First Name <span className="text-danger">*</span>
                                                    </label>
                                                    <div className="form-icon position-relative">
                                                        <input
                                                            type="text"
                                                            id="firstName"
                                                            className="form-control"
                                                            placeholder="First Name"
                                                            value={firstName}
                                                            onChange={this.handleChange}
                                                            required
                                                        />
                                                        <p className="text-danger">{errors.firstName}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <label className="form-label">
                                                        Your Email <span className="text-danger">*</span>
                                                    </label>
                                                    <div className="form-icon position-relative">
                                                        <input
                                                            type="email"
                                                            id="email"
                                                            className="form-control"
                                                            placeholder="Email"
                                                            value={email}
                                                            onChange={this.handleChange}
                                                            required
                                                        />
                                                        <p className="text-danger">{errors.email}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <label className="form-label">
                                                        Phone No. <span className="text-danger">*</span>
                                                    </label>
                                                    <div className="form-icon position-relative">
                                                        <input
                                                            type="number"
                                                            id="phone"
                                                            className="form-control"
                                                            placeholder="Phone No."
                                                            value={phone}
                                                            onChange={this.handleChange}
                                                            required
                                                        />
                                                        <p className="text-danger">{errors.phone}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <label className="form-label">
                                                        Inquiry <span className="text-danger">*</span>
                                                    </label>
                                                    <div className="form-icon position-relative">
                                                        <textarea
                                                            id="inquiry"
                                                            className="form-control"
                                                            placeholder="Enter Your Query..."
                                                            value={inquiry}
                                                            onChange={this.handleChange}
                                                            required
                                                        ></textarea>
                                                        <p className="text-danger">{errors.inquiry}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="d-grid">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                    >
                                                        Send
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </Col>
                            <Col lg={4} md={6} className="col-12 order-md-1 order-2 mt-4 pt-2">
                                <div className="me-lg-4">
                                    <div className="d-flex">
                                        <div className="icons text-center">
                                            <i className="uil uil-phone d-block rounded h4 mb-0"></i>
                                        </div>
                                        <div className="flex-1 ms-3">
                                            <h5 className="mb-2">Phone</h5>
                                            <p className="text-muted">+91 8019296262</p>
                                        </div>
                                    </div>
                                    <div className="d-flex mt-4">
                                        <div className="icons text-center">
                                            <i className="uil uil-envelope d-block rounded h4 mb-0"></i>
                                        </div>
                                        <div className="flex-1 ms-3">
                                            <h5 className="mb-2">Email</h5>
                                            <p className="text-muted">sales@zencruz.com</p>
                                        </div>
                                    </div>
                                    <div className="d-flex mt-4">
                                        <div className="icons text-center">
                                            <i className="uil uil-map-marker d-block rounded h4 mb-0"></i>
                                        </div>
                                        <div className="flex-1 ms-3">
                                            <h5 className="mb-2">Location</h5>
                                            <p className="text-muted">
                                                Sundaraiah Vignana Kendram, Survey No 91, Gachibowli Rd,
                                                Madhava Reddy Colony, Gachibowli, Hyderabad, Telangana 500032
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <MDBAlert
                    color={this.state.status}
                    autohide
                    position='top-center'
                    delay={5000}
                    appendToBody
                    open={alerttxt != ""}
                    onClose={this.handleralert}
                >
                    {alerttxt}
                </MDBAlert>
            </React.Fragment>
        );
    }
}

export default Contact;
