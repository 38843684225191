import React, { useState, useEffect } from "react";
import { Col, Card, Row, Table } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { customPremium, getAllPricingPlans } from "../../services/apiServices";
import FeatureText from "./FeatureText"
import {
    MDBBtn,
    MDBAlert,
    MDBModal,
    MDBModalDialog, MDBIcon,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
} from 'mdb-react-ui-kit';
import { planLimitation, plansFeature, pricingPlans } from "./pricingplans";
const Pricing = () => {
    const navigate = useNavigate();
    let data = {
        pricingPlans: pricingPlans.filter((obj) => obj.availability == true),
        planFeature: plansFeature,
        planLimitation: planLimitation
    }
    const [PlansData, setPlansData] = useState(data)
    const [Error2, setError2] = useState("")
    const [moreValue, setMoreValue] = useState(false);
    useEffect(() => {
        async function fetchData() {
            const response = await getAllPricingPlans();
            if (!response || response == 'false') {
                return;
            }
            let recinfo = await response.json();
            console.log(recinfo);
            if (recinfo && recinfo.pricingPlans) {
                let filterData = recinfo.pricingPlans.filter((obj) => obj.availability == true);
                recinfo.pricingPlans = filterData;
                setPlansData(recinfo);
            }
        }
        fetchData();
    }, []);
    const [activeTab, setActiveTab] = useState({});
    const buynowHandler = (e, plan) => {
        let value = 0;
        if (activeTab[plan._id] === "tab2") {
            value = 1;
        }
        e.preventDefault();
        if (!(localStorage.getItem("loggedUserInfo"))) {
            navigate(`/OtpValidation?id=${plan._id}&value=${value}`);
            return;
        } else {
            navigate(`/billingsubscriptionplan?id=${plan._id}&value=${value}`);
            return;
        }
    }
    const [fullscreenXlModal, setFullscreenXlModal] = useState(false);

    const toggleOpen = () => setFullscreenXlModal(!fullscreenXlModal);

    const [customModel, setCustomModel] = useState(false);

    const featureFields = [
        { field: "dashboard", label: "Dashboard" },
        { field: "membercheckin", label: "Member Check-In" },
        { field: "checkinreports", label: "Check-In Reports" },
        { field: "memebershipplans", label: "Membership Plans" },
        { field: "addons", label: "Addons" },
        { field: "timeslots", label: "Time Slots" },
        { field: "planswithsessionslimit", label: "Plans With Sessions Limit" },
        { field: "adminlogin", label: "Admin Login" },
        { field: "stafflogin", label: "Add Staff" },
        { field: "discountplans", label: "Discount Plans" },
        { field: "emailreceipt", label: "Email Receipt" },
        { field: "digitalwaiver", label: "Digital Waiver" },
        { field: "pos", label: "POS" },
        { field: "gstreceipts", label: "GST Receipt" },
        { field: "trainersessionssummary", label: "Trainer Sessions Summary" },
        { field: "loginwithgoogleaccount", label: "Login With Google Account" },
        { field: "paymentsummary", label: "Payment Summary Report" },
        { field: "plansummary", label: "Plan Summary Report" },
        { field: "sessionsummary", label: "Session Summary Report" },
        { field: "sessioncount", label: "Session Report" },
        { field: "revenuereport", label: "Revenue Report" },
        { field: "activememberhistory", label: "Active Member History Reports" },
    ];
    const limitFields = [
        { field: "activemembers", label: "Active Member(s)" },
        { field: "addons", label: "Addon(s)" },
        { field: "adminlogin", label: "Admin Login(s)" },
        { field: "memebershipplans", label: "Membership Plan(s)" },
        { field: "stafflogin", label: "Staff Login(s)" },
        { field: "timeslots", label: "Time Slot(s)" },
        { field: "transactions", label: "Transaction(s) (Monthly)" }
    ];

    const moduleFields = [
        { field: "accountantrole", label: "Accountant Role" },
        { field: "smsintergration", label: "SMS Integration" },
        { field: "whatsappintegration", label: "WhatsApp Integration" },
        // { field: "memberportal", label: "Member Portal" }
    ];

    const handleTabChange = (id, tab) => {
        setActiveTab((prev) => ({ ...prev, [id]: tab }));
    };
    const [formData, setFormData] = useState({
        firstName: '',
        email: '',
        phone: '',
        inquiry: ''
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Form Submitted:", formData);
        const fData = {
            fname: formData.firstName,
            email: formData.email,
            number: formData.phone,
            inquiry: formData.inquiry
        };
        console.log(fData);
        let savedObj = await customPremium(fData);
        if (savedObj === "false" || !savedObj) {
            setError2("Some thing went wrong. Please try again");
            return;
        }
        setError2("Thank you for reaching out to us. Your form has been sent successfully.");

        let plan = await savedObj.json();
        setCustomModel(false);
        console.log(plan);
    };
    return (
        <React.Fragment>
            {/* Start Pricing  */}
            <section className="section bg-light" id="pricing">
                <div className="container-fluid">
                    <Row className="justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center mb-4">
                                <h4 className="title fw-semibold mb-4">Pricing Plans</h4>
                                <MDBBtn size='sm' className="btn btn-primary btn-sm" onClick={toggleOpen}>Compare</MDBBtn>
                            </div>
                        </div>
                    </Row>
                    <Row className="d-flex justify-content-center pricingDiv">
                        <>
                            {PlansData && PlansData.pricingPlans && PlansData.pricingPlans.length > 0 ? (
                                PlansData.pricingPlans.map((item, key) => {
                                    let featureIndexOf = PlansData.pricingPlans.findIndex((obj) => obj._id === item._id);
                                    let prevFeature = PlansData.planFeature[featureIndexOf]; // Get the previous plan's feature
                                    let prevPricingPlan = PlansData.pricingPlans[featureIndexOf - 1]; // Get the previous plan's feature
                                    let feature = PlansData.planFeature.find((obj) => obj.id === item._id);
                                    let limitation = PlansData.planLimitation.find((obj) => obj.id === item._id);
                                    let currentTab = activeTab[item._id] || "tab1"; // Default to "tab1"

                                    return (
                                        <Col lg={2} md={6} sm={12} className="mt-3 pricingPlan" key={key}>
                                            <Card className="border-0 shadow prisingdiv">
                                                <div className="p-4 pb-1 border-bottom border-light">
                                                    <h6 className="fw-semibold mb-3 text-uppercase">{item.name}</h6>
                                                    {item.pricemonthly == 0 ? <div class="d-flex w-100">
                                                        <div class="tab-slider--tabs-single w-100 text-center" style={{ "color": "#2d2d2d" }}> 30 Days
                                                        </div>
                                                    </div> :
                                                        <div className="d-flex w-100">
                                                            <div className="tab-slider--nav">
                                                                <ul className={`tab-slider--tabs d-flex align-items-center ${currentTab === "tab2" ? "slide" : ""}`} >
                                                                    <li className={`tab-slider--trigger ${currentTab === "tab1" ? "active" : ""}`}
                                                                        onClick={() => handleTabChange(item._id, "tab1")}
                                                                        rel="tab1"
                                                                    >
                                                                        6 Months
                                                                    </li>
                                                                    <li
                                                                        className={`tab-slider--trigger ${currentTab === "tab2" ? "active" : ""}`}
                                                                        onClick={() => handleTabChange(item._id, "tab2")}
                                                                        rel="tab2"
                                                                    >
                                                                        12 Months
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>}
                                                    <div className="price-title">
                                                        {item.pricemonthly == 0 ?
                                                            <div className="d-flex my-2 justify-content-between align-items-end">
                                                                <div className="d-flex align-items-center">
                                                                    <span className="price h3 fw-mibold mb-0 mt-3">Free</span>
                                                                </div>
                                                            </div>
                                                            : <>
                                                                <div className="my-2">
                                                                    {currentTab === "tab2" ? <>
                                                                        <div className="d-flex align-items-center">
                                                                            <span className="price h4 fw-semibold mb-0 mt-3">
                                                                                <span className="text-danger text-decoration-line-through me-2">&#8377;{item.priceannual?.apricemontly}</span>
                                                                                &#8377;{item.priceannual?.fpricemontly}/<small>Month + GST</small>
                                                                            </span>
                                                                        </div>
                                                                        <span className="fs-6">
                                                                            Billed for 12 Months
                                                                        </span>
                                                                        <div className="d-flex my-0 justify-content-between align-items-end">
                                                                            {currentTab === "tab2" &&
                                                                                <p>You Save {item.priceannual?.discount}%
                                                                                    {/* <span className="ms-2 mb-1 mt-3 text-success bold">&#8377;{item.priceannual?.apricemontly - item.priceannual?.fpricemontly}/-</span> */}
                                                                                </p>
                                                                            }
                                                                        </div>
                                                                    </> : <>
                                                                        <div className="d-flex align-items-center">
                                                                            <span className="price h4 fw-semibold mb-0 mt-3">
                                                                                &#8377;{item.pricemonthly}/<small>Month + GST</small>
                                                                            </span>
                                                                        </div>
                                                                        <span className="fs-6">
                                                                            Billed for 6 Months
                                                                        </span>
                                                                    </>}
                                                                </div>
                                                            </>
                                                        }

                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between flex-column h-100 p-4">
                                                    <div>
                                                        <div className="pb-0">
                                                        {feature && <>
                                                            <h6 className="mb-3">Features:</h6>
                                                                <ul className="mb-0 list-unstyled planlist">
                                                                    {key != 0 &&
                                                                        <li className="text-muted">
                                                                            <span className="text-success h5 me-1">
                                                                                <i className="uil uil-plus-circle align-middle"></i>
                                                                            </span>
                                                                            {`All Features of ${prevPricingPlan?.name} Plan`}
                                                                        </li>}
                                                                {featureFields
                                                                    // Sort the featureFields array
                                                                    .sort((a, b) => {
                                                                        return a.label.localeCompare(b.label);
                                                                    })
                                                                    // Render the sorted features
                                                                        .map(({ field, label }) => (
                                                                            (key == 0 || prevFeature[field] === false)
                                                                            && feature[field] && (<>
                                                                                <FeatureText key={field} flag={feature[field]} value={label} />
                                                                            </>
                                                                            )
                                                                    ))}
                                                            </ul>
                                                        </>}
                                                        {limitation && <>

                                                            <h6 className="my-3">Limits:</h6>
                                                            <ul className="mb-0 list-unstyled planlist">
                                                                {limitFields.map(({ field, label }) => {
                                                                    const value = limitation[field] == -1 ? "Unlimited" : limitation[field];
                                                                    const flag = !(feature[field] === false || limitation[field] === 0);

                                                                    return flag && (<FeatureText key={field} value={`${value} ${label}`} flag={flag} />);
                                                                })}
                                                            </ul>
                                                        </>}

                                                        <h6 className="my-3">Additional Addons:</h6>
                                                        <ul className="mb-0 list-unstyled planlist">
                                                            <FeatureText value={`Accountant Role @Rs.${item.accountantrole} (Monthly)`} flag={true} />
                                                            <FeatureText value={`SMS Integration @Rs.${item.smsintergration} (Monthly)`} flag={true} />
                                                            <FeatureText value={`WhatsApp Integration @Rs.${item.whatsappintegration} (Monthly)`} flag={true} />
                                                        </ul>
                                                            {/* <span className="link cursor-pointer text-primary" onClick={() => setMoreValue(false)}>less...</span> */}
                                                        </div>

                                                    </div>
                                                    <div className="my-3">
                                                        <button className="btn btn-primary w-100" onClick={(e) => buynowHandler(e, item)}>Subscribe Now</button>
                                                    </div>
                                                </div>
                                            </Card>
                                        </Col>
                                    );
                                })
                            ) : ("")}
                            {/* Custom Premium */}
                            <Col lg={2} md={6} sm={12} className="mt-3 pricingPlan">
                                <Card className="border-0 shadow prisingdiv">
                                    <div className="p-4 pb-0 border-bottom border-light">
                                        <h6 className="fw-semibold mb-3 text-uppercase">Custom Premium</h6>
                                        <div class="d-flex w-100">
                                            <div class="tab-slider--tabs-single w-100 text-center" style={{ "color": "#2d2d2d" }}>Custom Term</div>
                                        </div>
                                        <div className="price-title">
                                            <div className="d-flex my-2 justify-content-between align-items-end">
                                                <div className="d-flex align-items-center mt-2">
                                                    <span className="price h4 fw-mibold mb-0 mt-3">Custom Pricing</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-4 h-100">
                                        <div className="d-flex justify-content-between flex-column h-100">
                                            <div>
                                                <h6 className="mb-3">Features:</h6>
                                                <ul className="mb-0 list-unstyled planlist">
                                                    <FeatureText flag={true} value={"All Customized Plan Features"} />
                                                    <FeatureText flag={true} value={"Customizable for Multi-Licenses and Multi-Year Plans"} />
                                                </ul>

                                                <h6 className="my-3">Limits:</h6>
                                                <ul className="mb-0 list-unstyled planlist">
                                                    <FeatureText value={"Customized based on requirements"} flag={true} />
                                                </ul>
                                                <h6 className="my-3">Additional Addons:</h6>
                                                <ul className="mb-0 list-unstyled planlist">
                                                    <FeatureText value={`Customized pricing for additional addons`} flag={true} />
                                                </ul>
                                            </div>

                                            <div className="my-3 mt-4">
                                                <button className="btn btn-primary w-100" onClick={(e) => setCustomModel(true)}>Contact Us</button>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </>
                    </Row>
                    {/* Pricing Plans Comparison */}
                    <MDBModal tabIndex='-1' open={fullscreenXlModal} onClose={() => setFullscreenXlModal(false)}>
                        <MDBModalDialog size='fullscreen'>
                            <MDBModalContent>
                                <MDBModalHeader>
                                    <MDBModalTitle>Pricing Plans Comparison</MDBModalTitle>
                                    <MDBBtn
                                        type='button'
                                        className='btn-close'
                                        color='none'
                                        onClick={toggleOpen}
                                    ></MDBBtn>
                                </MDBModalHeader>
                                <MDBModalBody style={{ maxHeight: "90vh" }}>

                                    {PlansData && PlansData.pricingPlans && PlansData.pricingPlans.length > 0 &&
                                        <div className="container p-0">

                                            <Table bordered hover responsive>
                                                <thead>
                                                    <tr>
                                                        <th>Plan</th>
                                                        {PlansData.pricingPlans.map((item, key) => (
                                                            <th key={key} className="text-center">
                                                                {item.name}
                                                                <br />

                                                                {
                                                                    item.pricemonthly == 0 ?
                                                                        <span className="price h5">Free</span>
                                                                        : <span className="price h5">&#8377;{item.pricemonthly}/<small>Month</small></span>
                                                                }
                                                            </th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {/* Features Section */}
                                                    <tr className="table-secondary">
                                                        <td colSpan={PlansData.pricingPlans.length + 1} className="text-center fw-bold">Features</td>
                                                    </tr>
                                                    {featureFields
                                                        .sort((a, b) => {
                                                            // Count ticks (checks) for each field in `featureFields`
                                                            const countTicksA = PlansData.pricingPlans.filter(plan => {
                                                                const featureData = PlansData.planFeature.find(f => f.id === plan._id);
                                                                return featureData && featureData[a.field];
                                                            }).length;

                                                            const countTicksB = PlansData.pricingPlans.filter(plan => {
                                                                const featureData = PlansData.planFeature.find(f => f.id === plan._id);
                                                                return featureData && featureData[b.field];
                                                            }).length;

                                                            // Primary sort: Rows with more ticks (checks) come first
                                                            if (countTicksA !== countTicksB) return countTicksB - countTicksA;

                                                            // Secondary sort: Alphabetical by label
                                                            return a.label.localeCompare(b.label);
                                                        })
                                                        .map(({ field, label }, index) => (
                                                            <tr key={index}>
                                                                <td>{label}</td>
                                                                {PlansData.pricingPlans.map((item, key) => {
                                                                    const featureData = PlansData.planFeature.find(f => f.id === item._id);
                                                                    return (
                                                                        <td key={key} className="text-center">
                                                                            {featureData && featureData[field] ? (
                                                                                <span className="text-success">
                                                                                    <MDBIcon fas className="fs-6" icon="check" />
                                                                                </span>
                                                                            ) : (
                                                                                <span className="text-danger">
                                                                                    <MDBIcon fas className="fs-5" icon="times" />
                                                                                </span>
                                                                            )}
                                                                        </td>
                                                                    );
                                                                })}
                                                            </tr>
                                                        ))}
                                                    {/* Limitations Section */}
                                                    <tr className="table-secondary">
                                                        <td colSpan={PlansData.pricingPlans.length + 1} className="text-center fw-bold">Limits</td>
                                                    </tr>
                                                    {limitFields.map(({ field, label }, index) => (
                                                        <tr key={index}>
                                                            <td>{label}</td>
                                                            {PlansData.pricingPlans.map((item, key) => {
                                                                const limitationData = PlansData.planLimitation.find(l => l.id === item._id);
                                                                const limitValue = limitationData ? (limitationData[field] === -1 ? "Unlimited" : limitationData[field]) : "N/A";
                                                                return <td key={key} className="text-center">{limitValue}</td>;
                                                            })}
                                                        </tr>
                                                    ))}

                                                    {/* Modules Section */}
                                                    <tr className="table-secondary">
                                                        <td colSpan={PlansData.pricingPlans.length + 1} className="text-center fw-bold">Additional Addons</td>
                                                    </tr>
                                                    {moduleFields.map(({ field, label }, index) => (
                                                        <tr key={index}>
                                                            <td>{label}</td>
                                                            {PlansData.pricingPlans.map((item, key) => (
                                                                <td key={key} className="text-center">
                                                                    {item[field] ? `@Rs.${item[field]}` : "N/A"}
                                                                </td>
                                                            ))}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td></td>
                                                        {PlansData.pricingPlans.map((item, key) => (
                                                            <td key={key} className="text-center">
                                                                <button className="btn btn-primary w-100" onClick={(e) => buynowHandler(e, item)}>Subscribe Now</button>
                                                            </td>
                                                        ))}
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </div>
                                    }
                                </MDBModalBody>
                            </MDBModalContent>
                        </MDBModalDialog>
                    </MDBModal>
                </div>
                <MDBModal open={customModel} onClose={() => setCustomModel(false)}>
                    <MDBModalDialog>
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>Custom Premium - Contact Us</MDBModalTitle>
                                <MDBBtn
                                    type='button'
                                    className='btn-close'
                                    color='none'
                                    onClick={() => setCustomModel(false)}
                                ></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody style={{ maxHeight: "90vh" }}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="form-label">
                                                First Name <span className="text-danger">*</span>
                                            </label>
                                            <div className="form-icon position-relative">
                                                <input
                                                    type="text"
                                                    id="firstName"
                                                    className="form-control"
                                                    placeholder="First Name"
                                                    value={formData.firstName}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="form-label">
                                                Your Email <span className="text-danger">*</span>
                                            </label>
                                            <div className="form-icon position-relative">
                                                <input
                                                    type="email"
                                                    id="email"
                                                    className="form-control"
                                                    placeholder="Email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="form-label">
                                                Phone Number. <span className="text-danger">*</span>
                                            </label>
                                            <div className="form-icon position-relative">
                                                <input
                                                    type="number"
                                                    id="phone"
                                                    className="form-control"
                                                    placeholder="Phone Number"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="form-label">
                                                Requirements <span className="text-danger">*</span>
                                            </label>
                                            <div className="form-icon position-relative">
                                                <textarea
                                                    id="inquiry"
                                                    className="form-control"
                                                    placeholder="Enter Your Query With Your Basic Requirements..."
                                                    value={formData.inquiry}
                                                    onChange={handleChange}
                                                    required
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="d-grid">
                                            <button type="button" onClick={handleSubmit} className="btn btn-primary">
                                                Send
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </MDBModalBody>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>

                <MDBAlert
                    color={Error2.includes("Thank") ? "success" : "danger"}
                    autohide
                    position='top-right'
                    delay={5000}
                    appendToBody
                    open={Error2 != ""}
                    onClose={() => setError2("")}
                >
                    {Error2}
                </MDBAlert>
            </section>
        </React.Fragment >
    );
}

export default Pricing;
