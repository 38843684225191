import React, { useEffect, useState } from "react";
import { browserName, browserVersion } from "react-device-detect";
import ReactGA from 'react-ga';
import { useNavigate } from "react-router";
import Popup from "../components/Popup";
import { getPlanProfile, getSlots, savePlan } from "../services/apiServices";
import { logEvent } from "../services/apiServices.js";
import { isEmpty } from "../validators/helper.js";
import addImg from "./../components/images/add.svg";
import CreateSlot from "./CreateSlot";
import { MDBInputGroup, MDBCheckbox } from 'mdb-react-ui-kit';
import { googleAnalytic } from "../components/googleAnalytics";
import PageError from "./PageError";


const CreatePlan = (props) => {
  const [apiError, setApiError] = useState(false);
  const [form, setForm] = useState({
    name: "",
    validity: "",
    validtype: "1",
    validtimetype: false,
    slots: "",
    days: "",
    discount: "2",
    type: "1",
    price: "",
    autocheckin: false,
    forcetrainer: false,
    enable: true,
    sessioncheck: false,
    sessionvalue: "",
    description: ""

  });
  const [isChecked, setIsChecked] = useState(true);
  const [error, setError] = useState({});
  const [slot, setSlots] = useState([]);

  const [weekday, setWeekDay] = useState([
    { day: 1 },
    { day: 2 },
    { day: 3 },
    { day: 4 },
    { day: 5 },
    { day: 6 },
    { day: 7 },
  ]);
  const [selslots, setSelSlots] = useState([]);
  const navigate = useNavigate();

  const [openAddSlot, setOpenAddSlot] = useState(false);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  function addNewSlots(e) {
    setOpenAddSlot(true);
  }
  const handleReload = () => {
    window.location.reload();
  }

  function closePopup() {
    setOpenAddSlot(false);
  }
  useEffect(() => {
    async function fetchSData() {
      if (localStorage.getItem("loggedUserInfo")) {
        const response = await getSlots(
          JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
        );
        console.log(response);
        if (!response) {

          return;
        }
        if (response === "false") {
          setApiError(true)
          return;
        }
        const record = await response.json();
        if (!record) {

          navigate("/");
          return;
        }

        setSlots(record);

        console.log(record);
        setSelSlots(record);
      }
    }
    fetchSData();
    return;
  }, [navigate]);
  useEffect(() => {
    async function fetchData() {
      const response = await getPlanProfile(props.id);
      console.log(response);
      if (!response) {
        return;
      }
      if (response === "false") {
        setApiError(true)
        return;
      }
      const record = await response.json();
      if (!record) {

        return;
      }
      console.log(record);
      setForm((prev) => {
        return { ...prev, ...record };
      });
      // setForm(record);
      setIsChecked(record.taxcheck);
      setSelSlots(record.slots);
      setWeekDay(record.days);
    }
    console.log(props.id);
    if (props.id !== null && props.id !== "-1") {
      fetchData();
    }
    return;
  }, [props.id, navigate]);

  function updateForm(value) {
    return setForm((prev) => {
      return { ...prev, ...value };
    });
  }
  console.log(form);
  function updateSlot(item) {
    console.log(item);
    setSlots([...slot, item]);
    setSelSlots([...selslots, item]);
    closePopup();
  }
  function handleDays(e, value) {
    let ischecked =
      weekday &&
      weekday.length > 0 &&
      weekday.filter((item) => item.day === value).length > 0;


    if (!ischecked) {
      if (!weekday || weekday.length === 0) {
        setWeekDay([{ day: value }]);
      } else {
        setWeekDay([...weekday, { day: value }]);
      }
    } else {
      const nWeekdays = weekday.filter((item) => item.day !== value);
      setWeekDay(nWeekdays);
    }

  }
  function handleSlots(e, titem) {

    let ischecked =
      selslots &&
      selslots.length > 0 &&
      selslots.filter((item) => item._id === titem._id).length > 0;
    console.log(ischecked);
    if (!ischecked) {
      if (!selslots || selslots.length === 0) {
        setSelSlots([{ _id: titem._id }]);
      } else {
        setSelSlots([...selslots, { _id: titem._id }]);
      }
    } else {
      if (!selslots || selslots.length === 0) {
        const nselslots = [{ _id: titem._id }];
        setSelSlots(nselslots);
      } else {
        const nselslots = selslots.filter((item) => item._id !== titem._id);
        setSelSlots(nselslots);
      }
    }
  }
  let validateForm = (e) => {
    console.log("Validating form...");
    let errors = {};

    if (isEmpty(form.name)) {
      errors.name = "Plan Name can't be blank";
    }
    if (isEmpty(form.validity)) {
      errors.validity = "Validity can't be blank";
    }

    if (isEmpty(form.price)) {
      errors.price = "Price can't be blank";
    }
    if (form.sessioncheck && isEmpty(form.sessionvalue)) {
      errors.sessionvalue = "Please Enter Value"
    }
    if (selslots.length === 0) {
      errors.slots = "Please select at least one slot";
    }
    if (weekday.length === 0) {
      errors.weekday = "Please select at least one  day";
    }
    console.log(errors);
    if (isEmpty(errors)) {
      return true;
    } else {
      return errors;
    }
  };

  async function onSubmit(e) {

    e.preventDefault();

    const updates = {
      name: form.name,
      validity: form.validity,
      validtype: form.validtype,
      validtimetype: form.validtimetype,
      slots: selslots,
      days: weekday,
      discount: form.discount,
      type: form.type,
      price: form.price,
      taxcheck: isChecked,
      enable: form.enable,
      sessioncheck: form.sessioncheck,
      sessionvalue: form.sessionvalue,
      forcetrainer: form.forcetrainer,
      autocheckin: form.autocheckin,
      description: form.description,
      _id: props.id && props.id !== -1 ? props.id : null,
    };

    let errors = validateForm(e);
    if (errors === true) {
      console.log(updates);
      let savedObj = await savePlan(
        updates,
        localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : ""
      );
      if (savedObj === "false") {
        setApiError(true)
        return;
      }
      let plan = await savedObj.json();
      console.log(plan);
      let Eventtype = "plan";
      let gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
      let username1 = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : '';
      let ip = localStorage.getItem("ipaddress");
      let browserinfo = browserName + "-" + browserVersion + ".0.0";
      if (props.id === plan._id) {
        let flag = `Plan Updated Successfull (${updates.name})`;
        await googleAnalytic("createPlan", 'Plan', 'Save Button', 'Plan Updated')
        // ReactGA.event({
        //   category: 'Plan',
        //   action: 'Save Button',
        //   label: 'Plan Updated',
        // });
        logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, gymid);
        props.onsubmit(plan);
      }
      else {
        let flag = `Plan Created Successfull (${updates.name})`;
        await googleAnalytic("createPlan", 'Plan', 'create Button', 'Plan Created')
        // ReactGA.event({
        //   category: 'Plan',
        //   action: 'create Button',
        //   label: 'Create Plan',
        // });
        logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, gymid);
        localStorage.setItem("setting_save_plan", "Plan saved successfully");
        props.onsubmit(plan);
      }
      window.location.href = "/plansandaddons";
    }
    else {
      setError(errors);
    }
  }

  /************
   * This handleforcetrainerChange is function has been created on [14-3-2024]
   * 
   * created by vamshi
   * @function
   * @description
   * handleforcetrainerChange is function to handle changes in forcetrainer checkbox
   * **********/
  const handleForceTrainerChange = (e) => {
    const isChecked = e.target.checked;
    updateForm({ forcetrainer: isChecked, autocheckin: !isChecked ? form.autocheckin : false })

  };
  /************
    * This handleAutoCheckinChange is function has been created on [14-3-2024]
    * 
    * created by vamshi
    * @function
    * @description
    * handleAutoCheckinChange is function to handle changes in autocheckin checkbox
    * **********/
  const handleAutoCheckinChange = (e) => {
    const isChecked = e.target.checked;
    updateForm({ forcetrainer: !isChecked ? form.forcetrainer : false, autocheckin: isChecked })
  };
  let plansInfo = localStorage.getItem("planInfo") ? JSON.parse(localStorage.getItem("planInfo")) : null;
  let slotValue = true;
  if (plansInfo != null && plansInfo.planFeature.timeslots && (plansInfo.planLimitation.timeslots != -1 && (plansInfo.planLimitation.timeslots <= slot.length))) {
    slotValue = false;
  }
  return (
    <>
      {apiError ? (
        <PageError handleReload={handleReload} />
      ) : <>
        {!props.viewmode && (
          <>
            <div className="gym-container px-0"  >
              <form
                action="#"
                target="_blank"
                onSubmit={onSubmit}
                className="business-form"
              >
                <div className="popup-bussiness">
                  <div className="col-4 gym-section gym-half">
                    <label>Plan Name:<span className="text-danger">*</span></label>
                    <input
                      className="gym-input"
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Plan Name"
                      value={form.name}
                      onChange={(e) => updateForm({ name: e.target.value })}
                    />
                    {error.name && (
                      <>
                        <p className="clr-red">{error.name}</p>
                      </>
                    )}
                  </div>

                  <div className="col-4 gym-section gym-half">
                    <label>Validity:<span className="text-danger">*</span></label>{" "}
                    <MDBInputGroup className='mb-3'>
                      <input
                        className="gym-input w-40"
                        type="number"
                        name="validity"
                        id="validity"
                        maxLength={2}
                        placeholder="30"
                        value={form.validity}
                        onChange={(e) => updateForm({ validity: e.target.value })}
                      />

                      <select
                        name="valid_days"
                        id="valid_days"
                        className="gym-input w-50"
                        value={form.validtype}
                        onChange={(e) => updateForm({ validtype: e.target.value })}
                      >
                        <option value="1" selected>
                          Day(s)
                        </option>
                        <option value="2">Month(s)</option>
                        <option value="3">Year(s)</option>
                      </select>
                    </MDBInputGroup>
                    {error.validity && (
                      <>
                        <p className="clr-red">{error.validity}</p>
                      </>
                    )}

                  </div>
                  {(plansInfo === null || (plansInfo.planFeature && plansInfo.planFeature.planswithsessionslimit)) ? <>

                    <div className="col-4 gym-section gym-half">
                      <div className="d-flex mt-4">

                        <MDBCheckbox name='flexCheck' checked={form.sessioncheck} id='flexCheckDefault' label='Session Limit' onChange={() => updateForm({ sessioncheck: !form.sessioncheck })} />
                        {form.sessioncheck && <input
                          className="gym-input w-30 ms-2"
                          type="number"
                          name="validity"
                          id="validity"
                          maxLength={2}
                          placeholder="30"
                          value={form.sessionvalue}
                          onChange={(e) => updateForm({ sessionvalue: e.target.value })}
                        />}

                      </div>
                    </div>{error.sessionvalue && (
                      <>
                        <p className="clr-red ms-3 ps-4">{error.sessionvalue}</p>
                      </>
                    )}
                  </> : ""}

                  <div className="gym-section">
                    <label className="text-start w-100">Valid Days:</label>
                    {error.weekday && (
                      <p className="clr-red">{error.weekday}</p>
                    )}
                    <div className="allowedslotsall">
                      <div
                        className={
                          weekday &&
                            weekday.length > 0 &&
                            weekday.filter((item) => item.day === 1).length > 0
                            ? "slot-datepactive"
                            : "slot-datep"
                        }
                      >
                        <div>
                          <div
                            className="d-flex"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => handleDays(e, 1)}
                          >
                            <input
                              name="sunday"
                              type="checkbox"
                              className="gym-input w-inherit"
                              checked={
                                weekday &&
                                weekday.length > 0 &&
                                weekday.filter((item) => item.day === 1).length > 0
                              }
                              onChange={(e) => handleDays(e, 1)}
                            />
                            &nbsp;Sun
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          weekday &&
                            weekday.length > 0 &&
                            weekday.filter((item) => item.day === 2).length > 0
                            ? "slot-datepactive"
                            : "slot-datep"
                        }
                      >
                        <div>
                          <div
                            className="d-flex"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => handleDays(e, 2)}
                          >
                            <input
                              name="monday"
                              type="checkbox"
                              className="gym-input w-inherit"
                              checked={
                                weekday &&
                                weekday.length > 0 &&
                                weekday.filter((item) => item.day === 2).length > 0
                              }
                              onChange={(e) => handleDays(e, 2)}
                            />
                            &nbsp;Mon
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          weekday &&
                            weekday.length > 0 &&
                            weekday.filter((item) => item.day === 3).length > 0
                            ? "slot-datepactive"
                            : "slot-datep"
                        }
                      >
                        <div>
                          <div
                            className="d-flex"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => handleDays(e, 3)}
                          >
                            {" "}
                            <input
                              name="tuesday"
                              type="checkbox"
                              className="gym-input w-inherit"
                              checked={
                                weekday &&
                                weekday.length > 0 &&
                                weekday.filter((item) => item.day === 3).length > 0
                              }
                              onChange={(e) => handleDays(e, 3)}
                            />
                            &nbsp;Tues
                          </div>
                        </div>
                      </div>{" "}
                      <div
                        className={
                          weekday &&
                            weekday.length > 0 &&
                            weekday.filter((item) => item.day === 4).length > 0
                            ? "slot-datepactive"
                            : "slot-datep"
                        }
                      >
                        <div>
                          <div
                            className="d-flex"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => handleDays(e, 4)}
                          >
                            <input
                              name="wednesday"
                              type="checkbox"
                              className="gym-input w-inherit"
                              checked={
                                weekday &&
                                weekday.length > 0 &&
                                weekday.filter((item) => item.day === 4).length > 0
                              }
                              onChange={(e) => handleDays(e, 4)}
                            />
                            &nbsp; Wed
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          weekday &&
                            weekday.length > 0 &&
                            weekday.filter((item) => item.day === 5).length > 0
                            ? "slot-datepactive"
                            : "slot-datep"
                        }
                      >
                        <div>
                          <div
                            className="d-flex"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => handleDays(e, 5)}
                          >
                            <input
                              name="thursday"
                              type="checkbox"
                              className="gym-input w-inherit"
                              checked={
                                weekday &&
                                weekday.length > 0 &&
                                weekday.filter((item) => item.day === 5).length > 0
                              }
                              onChange={(e) => handleDays(e, 5)}
                            />
                            &nbsp;Thu
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          weekday &&
                            weekday.length > 0 &&
                            weekday.filter((item) => item.day === 6).length > 0
                            ? "slot-datepactive"
                            : "slot-datep"
                        }
                      >
                        <div>
                          <div
                            className="d-flex"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => handleDays(e, 6)}
                          >
                            <input
                              name="friday"
                              type="checkbox"
                              className="gym-input w-inherit"
                              checked={
                                weekday &&
                                weekday.length > 0 &&
                                weekday.filter((item) => item.day === 6).length > 0
                              }
                              onChange={(e) => handleDays(e, 6)}
                            />
                            &nbsp;Fri
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          weekday &&
                            weekday.length > 0 &&
                            weekday.filter((item) => item.day === 7).length > 0
                            ? "slot-datepactive"
                            : "slot-datep"
                        }
                      >
                        <div>
                          <div
                            className="d-flex"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => handleDays(e, 7)}
                          >
                            <input
                              name="saturday"
                              type="checkbox"
                              className="gym-input w-inherit"
                              checked={
                                weekday &&
                                weekday.length > 0 &&
                                weekday.filter((item) => item.day === 7).length > 0
                              }
                              onChange={(e) => handleDays(e, 7)}
                            />
                            &nbsp;Sat
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="gym-section">
                    <div>
                      <div className="slot-img">
                        <span>Allowed Slots:</span>
                        {slotValue ? <img
                          src={addImg}
                          style={{ cursor: "pointer" }}
                          onClick={(event) => addNewSlots(event)}
                          alt="Add Slots"
                        /> : ""}
                        {error.selslots && (
                          <>
                            <p className="clr-red">{error.selslots}</p>
                          </>
                        )}
                        {error.slots && (
                          <p className="clr-red ms-3">{error.slots}</p>
                        )}
                      </div>
                      <div className="allowedslotsall">

                        {slot.length > 0 && (
                          <>
                            {slot.map((item) => (
                              <div
                                className={
                                  selslots &&
                                    selslots.length > 0 &&
                                    selslots.filter(
                                      (titem) => titem._id === item._id
                                    ).length > 0
                                    ? "allowedslotsmain-boxactive"
                                    : "allowedslotsmain-box"
                                }
                              >
                                <div
                                  className="allowedslot-box"
                                  onClick={(e) => handleSlots(e, item)}
                                >
                                  <div>
                                    <input
                                      type="checkbox"
                                      className="gym-input"
                                      checked={
                                        selslots &&
                                        selslots.length > 0 &&
                                        selslots.filter(
                                          (titem) => titem._id === item._id
                                        ).length > 0
                                      }
                                      onChange={(e) => handleSlots(e, item)}
                                    />
                                  </div>
                                  <div className="slottime">
                                    <span>{item.name}</span>
                                    <span>
                                      {item.shrs} :
                                      {item.smins === "1"
                                        ? "00"
                                        : item.smins === "2"
                                          ? "15"
                                          : item.smins === "3"
                                            ? "30"
                                            : "45"}{" "}
                                      {item.shflag === "1" ? "AM" : "PM"} to{" "}
                                      {item.ehrs} :
                                      {item.emins === "1"
                                        ? "00"
                                        : item.emins === "2"
                                          ? "15"
                                          : item.emins === "3"
                                            ? "30"
                                            : "45"}{" "}
                                      {item.ehflag === "1" ? "AM" : "PM"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                        {slot.length <= 0 && (
                          <>
                            <div> No slots created yet</div>
                            <br />
                            <br />
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="slot-foot">
                    <div className="gym-section gym-half">
                      <label>Plan Type:</label>
                      <div>
                        <select
                          name="membershiptypes"
                          id="membershiptypes"
                          className="gym-input"
                          value={form.type}
                          onChange={(e) => updateForm({ type: e.target.value })}
                        >
                          <option value="1">One-time</option>
                          <option value="2">Recuring</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-3 gym-section gym-half">
                      <label>Allowed Discount:</label>{" "}
                      <div>
                        <select
                          name="discount"
                          id="discount"
                          className="gym-input"
                          value={form.discount}
                          onChange={(e) => updateForm({ discount: e.target.value })}
                        >
                          <option value="0">0%</option>
                          <option value="1">5%</option>
                          <option value="2" selected>
                            10%
                          </option>
                          <option value="3">15%</option>
                          <option value="4">50%</option>
                        </select>
                      </div>
                    </div>

                    <div className="gym-section gym-half">
                      <label>Plan Price:<span className="text-danger">*</span></label>
                      <input
                        className="gym-input"
                        type="text"
                        name="price"
                        id="price"
                        placeholder="Price"
                        value={form.price}
                        onChange={(e) => updateForm({ price: e.target.value })}
                      />
                      {error.price && (
                        <>
                          <p className="clr-red">{error.price}</p>
                        </>
                      )}
                      <p className="d-flex align-items-center mt-2 mb-0">
                        <input type="checkbox" name="tax" checked={isChecked} onChange={() => { setIsChecked(!isChecked) }}></input><label className="ms-2">Include Tax</label>
                      </p>
                    </div>

                    <div className="col-3 gym-section gym-half">
                      <div className="d-flex align-items-center mt-4">
                        <label className="switchlabel mb-0">24 Hours:</label>
                        <label className="switch">
                          <input className="form-control" type="checkbox" checked={form.validtimetype} onChange={(e) => updateForm({ validtimetype: !form.validtimetype })} />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="slot-foot mb-2">
                    <div className="col-md-4 gym-section gym-half d-flex flex-row align-items-center   my-0">
                      <label className="mb-0 switchlabel">Enable/Disable Plan:</label>
                      <label className="switch">
                        <input className="form-control" type="checkbox" checked={form.enable} onChange={(e) => updateForm({ enable: !form.enable })} />
                        <span className="slider round"></span>
                      </label>
                    </div>
                    {(plansInfo === null || (plansInfo.planFeature && plansInfo.planFeature.membercheckin)) && <>
                      <div className="col-md-5 gym-section gym-half d-flex flex-row align-items-center my-0">
                        <label className="mb-0 switchlabel">Force Trainer at Check-in: </label>
                        <label className="switch">
                          <input className="form-control" type="checkbox" checked={form.forcetrainer} onChange={handleForceTrainerChange} />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <div className="col-md-3 gym-section gym-half d-flex flex-row align-items-center my-0">
                        <label className="mb-0 switchlabel">Auto Check-in: </label>
                        <label className="switch">
                          <input className="form-control" type="checkbox" checked={form.autocheckin} onChange={handleAutoCheckinChange} />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </>}
                  </div>
                </div>

                <div className="w-100 mt-1">

                  <label className="switchlabel">Description: </label>
                  <textarea className='p-2 w-100 textarea'
                    id='form6Example7'
                    label=''
                    rows='5'
                    placeholder='Describe about this Plan here...'
                    name='description'
                    maxLength={1024}
                    value={form.description || ""}
                    onChange={(e) => updateForm({ description: e.target.value })}

                  />
                </div>
                <div className="mb-3 mt-3">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-mdb-dismiss="modal"
                  >
                    Save
                  </button>{" "}
                  &nbsp;
                  <button
                    to="#"
                    onClick={props.onclose}
                    className="btn btn-secondary"
                    data-mdb-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
        {(plansInfo === null || (plansInfo.planFeature && plansInfo.planFeature.timeslots)) && <>
          {openAddSlot && (
            <Popup
              title="Create/Edit Slot"
              ptype="modal-lg"
              content={
                <>
                  <div>
                    <CreateSlot
                      ispopup="0"
                      onsubmit={updateSlot}
                      onclose={closePopup}
                      oncancel={closePopup}
                    />
                  </div>
                </>
              }
              handleClose={closePopup}
            />
          )}
        </>}
      </>}</>
  );
};

export default CreatePlan;