import React, { Component } from "react";


class Faqs extends Component {
    render() {
        return (
            <div>
                <div className="container p-14 font">

                    <section>
                        <h5>1. What is EasyMembr?</h5>
                        <p>EasyMembr is a web-based member management software designed to streamline operations for gyms, fitness centers, clubs, and other membership-based businesses. It helps manage member registrations, attendance tracking, payments, and more.</p>

                        <h5>2. Who can use EasyMembr?</h5>
                        <p>EasyMembr is suitable for:</p>
                        <ul>
                            <li>Gyms and fitness centers</li>
                            <li>Yoga and wellness studios</li>
                            <li>Clubs and associations</li>
                            <li>Sports academies</li>
                            <li>Any membership-based business</li>
                        </ul>

                        <h5>3. What are the key features of EasyMembr?</h5>
                        <p>EasyMembr includes:</p>
                        <ul>
                            <li>Member registration and profile management</li>
                            <li>Check-in/check-out tracking</li>
                            <li>Attendance tracking</li>
                            <li>Payment and billing management</li>
                            <li>Digital waiver forms</li>
                            <li>Reporting and analytics</li>
                        </ul>

                        <h5>4. Can EasyMembr handle multiple locations?</h5>
                        <p>Yes, EasyMembr supports businesses with multiple locations, enabling seamless management across all branches.</p>

                        <h5>5. Does EasyMembr have a mobile app?</h5>
                        <p>Currently, EasyMembr is a web application accessible through any device with an internet connection and a web browser.</p>

                        <h5>6. Can I integrate EasyMembr with other software?</h5>
                        <p>Yes, EasyMembr supports integration with other tools and platforms through APIs, provided the external software also offers integration capabilities.</p>

                        <h5>7. Can EasyMembr integrate with physical devices like smartwatches?</h5>
                        <p>At this time, EasyMembr does not support integration with physical devices such as smartwatches.</p>

                        <h5>8. Is member data secure on EasyMembr?</h5>
                        <p>Yes, we employ advanced encryption and data protection measures to ensure your members’ information is secure.</p>

                        <h5>9. Does EasyMembr offer customization?</h5>
                        <p>Yes, EasyMembr can be customized to fit your business needs, including branding, workflows, and feature preferences.</p>

                        <h5>10. What is the pricing structure for EasyMembr?</h5>
                        <p>Our pricing is flexible and based on your business size and feature requirements. Contact our sales team for a tailored pricing plan.</p>

                        <h5>11. Is there a free trial available?</h5>
                        <p>Yes, you can explore EasyMembr with our 30-day free trial and 90 Days Cashback trial to determine if it meets your needs. Fill the contact us form, and our team will get in touch with you.</p>

                        <h5>12. What kind of support does EasyMembr provide?</h5>
                        <p>We offer comprehensive support through email and contact us form.</p>

                        <h5>13. How do I get started with EasyMembr?</h5>
                        <p>To get started, simply visit our website, sign up for a free trial, and begin exploring the tools and features EasyMembr offers.</p>

                    </section>
                </div>
            </div>


        )
    }
}

export default Faqs;
