import React, { useState } from "react";
import AddAllMembers from "./AddallUsers";
import AddformMembers from "./Addformusers";

const AddMembers = (props) => {

  const [submited, setSubmited] = useState("false")
  const [hidecheckbox, setHidecheckbox] = useState(true)
  const handleCheckboxChange = () => {

    localStorage.setItem("page", 1);
    setSubmited(submited === "true" ? "false" : "true");

  };
  const handleform = () => {
    setHidecheckbox(!hidecheckbox)
  }

  return (
    <>
      <div className="gym-main p-0 rightContent">

        {hidecheckbox && <div className="d-flex align-items-center pos-submittedwaivers justify-content-end mob-waiver-text py-2 ms-2">
          <input
            type="checkbox"
            id="flexCheckDefault"
            checked={submited === "false" ? false : true}
            onChange={handleCheckboxChange}
          />
          <label className="ms-1" htmlFor="flexCheckDefault">Show only new waivers</label>
        </div>}
        {submited !== "true" ? <AddAllMembers props={props} handleform={() => handleform()} /> :
          <AddformMembers props={props} handleform={() => handleform()} />}
      </div>
    </>
  );
};

export default AddMembers;
