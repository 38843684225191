import React, { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import ReactGA from 'react-ga';
import Timer from "../components/timer";
import {
    getAttendanceList
} from "../services/apiServices";
import calImg from "./../components/images/calendar.svg";


const SattendanceList = (props) => {
    console.log(props)
    let todaydate = new Date();

    const firstDay = new Date(todaydate);
    firstDay.setDate(todaydate.getDate() - 30);
    firstDay.setHours(0, 0, 0, 0);

    const lastDay = new Date(todaydate);
    lastDay.setDate(todaydate.getDate() + 1);
    const [dateRange, setDateRange] = useState([firstDay, lastDay]);
    const [startDate, endDate] = dateRange;
    // const [value, setvalue] = useState("");
    const [usersData, setData] = useState("");
    const [timetoshow, setTimetoshow] = useState((props.data && props.data.attendance?.length > 0) ? props.data.attendance[0].intime : new Date())


    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);

    function getDateRange(value) {
        console.log(value);
        setDateRange(value);
        if (value && value.length === 2) {
            let fdate = getRDateTime(value[0]).date;
            let tdate = getRDateTime(value[1]).date;
            console.log(fdate + ", " + tdate);
            // fetchData(fdate, tdate);
        }
    }
    function getRDateTime(tdbtime) {
        var now = new Date(tdbtime);
        var year = now.getFullYear();
        var month = now.getMonth() + 1;
        var day = now.getDate();
        var hour = now.getHours();
        var minute = now.getMinutes();
        var second = now.getSeconds();
        if (month.toString().length === 1) {
            month = "0" + month;
        }
        if (day.toString().length === 1) {
            day = "0" + day;
        }
        if (hour.toString().length === 1) {
            hour = "0" + hour;
        }
        if (minute.toString().length === 1) {
            minute = "0" + minute;
        }
        if (second.toString().length === 1) {
            second = "0" + second;
        }
        var dateTime = {
            date: year + "-" + month + "-" + day,
            time: hour + ":" + minute,
        };
        return dateTime;
    }
    function getDateTime(tdbtime) {
        var now = new Date(tdbtime);
        var year = now.getFullYear();
        var month = now.getMonth() + 1;
        var day = now.getDate();
        var hour = now.getHours();
        var minute = now.getMinutes();
        var second = now.getSeconds();
        if (month.toString().length === 1) {
            month = "0" + month;
        }
        if (day.toString().length === 1) {
            day = "0" + day;
        }
        if (hour.toString().length === 1) {
            hour = "0" + hour;
        }
        if (minute.toString().length === 1) {
            minute = "0" + minute;
        }
        if (second.toString().length === 1) {
            second = "0" + second;
        }
        var dateTime = {
            date: year + "-" + month + "-" + day,
            time: hour + ":" + minute,
        };
        return dateTime;
    }

    const CalenderInput = forwardRef(({ value, onClick }, ref) => (
        <span className="gym-date">
            {value === "" ? "Custom Range" : value}
            &nbsp;
            <img
                src={calImg}
                onClick={onClick}
                ref={ref}
                alt="custom Range"
                title="Select Custom Range"
            />
        </span>
    ));

    async function checkinUser(e, mId, chekinflag, obj) {
        console.log(chekinflag)
        e.preventDefault();
        setTimetoshow(new Date())
        props.oncheckin(e, obj, []);
        console.log(props.data);
        console.log(chekinflag)
        if (chekinflag === false) {
            let value = {
                bId: localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "",
                createdAt: new Date(),
                updatedAt: new Date(),
                date: new Date(),
                id: mId,
                intime: new Date(),
                member_type: "2",
                _id: mId,
            }
            props.data.attendance.unshift(value)
        }
        else {
            props.data.attendance[0].outtime = new Date()

        }

    }
    function getDiff(strt1, strt2) {
        var diff = (strt2.getTime() - strt1.getTime()) / 1000;
        diff /= 60;

        return Math.abs(Math.round(diff));
    }
    let checkInValue = props.data.attendance.length > 0 ?
        (props.data.attendance[0].intime && !props.data.attendance[0].outtime ? true : false) : false;
    useEffect(() => {

        async function fetchData(fdate, tdate) {
            const response = await getAttendanceList(props.id, {
                fdate: fdate,
                tdate: tdate,
                gid: localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : -1
            });
            if (!response) {
                return;
            }
            if (response == false) {
                return;
            }
            if (response === "false") {
                return;
            }
            const usersData = await response.json();
            if (!usersData) {
                return;
            }
            setData(usersData);
            console.log(usersData);
        }


        fetchData(getRDateTime(startDate).date, getRDateTime(endDate).date);
    }, [props.id, startDate, endDate]);
    return (
        <>
            <div className="table-scroll" id="contact">
                <div className="d-flex space-between mob-block">
                    <div className="insidecheck">
                        <span className="checkIn-Head">
                            {props.data.fname
                                ? props.data.fname + " " + props.data.lname
                                : props.data.firstname + " " + props.data.lastname}
                        </span>
                        <p>

                            {props.data.sex ? props.data.sex : ""}{props.data.sex && (props.data.age || props.data.sex) ? ', ' : ' '}
                            {props.data.age ? props.data.age + "y " : ""}{props.data.age && (props.data.weight || props.data.number) ? ', ' : ''}
                            {props.data.weight ? props.data.weight + "kgs " : ""}{props.data.weight && (props.data.number) ? ', ' : ''}
                            {props.data.number ? props.data.number + " " : ""}
                        </p>
                    </div>
                    <div className="d-flex align-center mob-block">
                        <div className="view-boxdate mob-block">
                            {" "}
                            <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(update) => {
                                    getDateRange(update);
                                }}
                                isClearable={false}
                                customInput={<CalenderInput />}
                            />
                        </div>


                        <div>
                            <div
                                className={
                                    (checkInValue)
                                        ? "checkindate checkinbuttonout mob-block mob-w-100"
                                        : "checkindate mob-block mob-w-100"
                                }
                            >
                                <input
                                    type="button"
                                    value={
                                        (checkInValue) ? "Check-Out" : "Check-In"
                                    }
                                    className={
                                        (checkInValue)
                                            ? "checkinbuttonout mob-block mob-w-100"
                                            : "checkinbutton mob-block mob-w-100"
                                    }
                                    onClick={(e) =>
                                        checkinUser(e, props.data._id, (checkInValue), props.data)
                                        // props.oncheckin(e, props.data._id, props.data.checkedin)
                                    }
                                />
                                {(checkInValue) && (
                                    <p className="d-flex m-inherit">
                                        <Timer
                                            member={props.data.fname}
                                            time={timetoshow}
                                        />
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="daterangemain P-10">
                    <div className="datechange">
                        <span>
                            {props.firstname} {props.lastname}
                        </span>
                    </div>
                </div>
                <div>
                    <div className="FixedtableHeader table-flow sno">
                        <table>
                            <thead>
                                <tr>
                                    <th>S.no</th>
                                    <th colSpan={2}>Date</th>
                                    <th>Check-In</th>
                                    <th>Check-Out</th>
                                    <th>Hours spent</th>
                                </tr>
                            </thead>
                            <tbody>
                                {usersData &&
                                    usersData.attendance.map((item, index) => (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td colSpan={2}>{getDateTime(item.date).date}</td>
                                            <td>
                                                {getDateTime(item.intime).time === "NaN:NaN"
                                                    ? " -- "
                                                    : getDateTime(item.intime).time}
                                            </td>
                                            <td>
                                                {getDateTime(item.outtime).time === "NaN:NaN"
                                                    ? "-- "
                                                    : getDateTime(item.outtime).time}
                                            </td>
                                            <td>
                                                {(
                                                    "0" +
                                                    Math.floor(
                                                        getDiff(
                                                            new Date(item.outtime),
                                                            new Date(item.intime)
                                                        ) / 60
                                                    )
                                                ).slice(-2) === "aN"
                                                    ? "--"
                                                    : (
                                                        "0" +
                                                        Math.floor(
                                                            getDiff(
                                                                new Date(item.outtime),
                                                                new Date(item.intime)
                                                            ) / 60
                                                        )
                                                    ).slice(-2)}
                                                :
                                                {(
                                                    "0" +
                                                    Math.floor(
                                                        getDiff(
                                                            new Date(item.outtime),
                                                            new Date(item.intime)
                                                        ) % 60
                                                    )
                                                ).slice(-2) === "aN"
                                                    ? "--"
                                                    : (
                                                        "0" +
                                                        Math.floor(
                                                            getDiff(
                                                                new Date(item.outtime),
                                                                new Date(item.intime)
                                                            ) % 60
                                                        )
                                                    ).slice(-2)}{" "}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

        </>
    );
};

export default SattendanceList;