import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

import Navbar from "../../component/Navbar";
import Pricing from "../../component/Pricing";
// import Review from "../../component/Review";
// import News from "../../component/News";
import Contact from "../../component/Contact";
import Footer from "../../component/Footer";
import Feature from "../../component/Feature";
// import Privacy from "../../../components/privacy";
import { initialize, pageview } from 'react-ga';
// import AmazonImage from '../../assets/images/client/amazon.svg';
// import GoogleImage from '../../assets/images/client/google.svg';
// import LenovoImage from '../../assets/images/client/lenovo.svg';
// import PaypalImage from '../../assets/images/client/paypal.svg';
// import ShopifyImage from '../../assets/images/client/shopify.svg';
// import SpotifyImage from '../../assets/images/client/spotify.svg';
// import LogoIcon from '../../assets/images/logo-light.png';
import { Link as ScrollLink } from 'react-scroll'; // Import react-scroll Link
import Laptop from '../../assets/images/bg/laptop.png';
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";

class Saas extends Component {
    constructor(props) {
        super(props);

        window.addEventListener("scroll", this.windowScroll);
    }
    /**
     * Window scroll 
     */

    windowScroll() {
        const navbar = document.getElementById("navbar");
        if (navbar) {
            if (
                document.body.scrollTop >= 50 ||
                document.documentElement.scrollTop >= 50
            ) {
                navbar.classList.add("nav-sticky");
            } else {
                navbar.classList.remove("nav-sticky");
            }
        }
    }

    componentDidMount() {
        const frontEndEnvData = localStorage.getItem("frontEndEnvData");
        const googleAnalyticId = frontEndEnvData ? JSON.parse(frontEndEnvData)?.reactappgoogleanalyticsid || "G-HWWFT9073S" : "G-HWWFT9073S";

        initialize(googleAnalyticId);

        // initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
        pageview("/Homepage");
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    <Navbar />
                    {/* Hero Start  */}
                    <section className="bg-half-170 d-table w-100 overflow-hidden bg-soft-primary" id="home">
                        <MDBContainer >
                            <MDBRow className="align-items-center">
                                <MDBCol sm={12} lg={6} md={6}>
                                    <div className="title-heading">
                                        <h1 className="heading my-3">Streamline Your GYM Operations with EasyMembr
                                        </h1>
                                        <p className="para-desc text-muted">Manage your gym's day-to-day operations with ease using EasyMembr, the all-in-one gym administration app designed for gym owners and managers.

                                        </p>
                                        <div className="mt-4 pt-2">
                                            <div className="mt-4 pt-2">
                                                <ScrollLink
                                                    to="pricing"
                                                    smooth={true}
                                                    duration={50}
                                                    className="btn btn-primary"
                                                >
                                                    Buy Now
                                                </ScrollLink>
                                            </div>
                                            {/* <Link to="/signup" className="btn btn-primary">Buy Now</Link> */}
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol sm={12} lg={6} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                                    <img src={Laptop} className="homepageImage" />
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </section>
                    {/* Hero End  */}

                    {/* Partners start */}

                    {/* <section className="mt-5 pt-md-5">
                        <Container>
                            <Row className="justify-content-center">
                                <div className="col-lg-2 col-md-2 col-6 text-center py-4 py-sm-0">
                                    <img src={AmazonImage} className="avatar avatar-ex-sm" alt="" />
                                </div>

                                <div className="col-lg-2 col-md-2 col-6 text-center py-4 py-sm-0">
                                    <img src={GoogleImage} className="avatar avatar-ex-sm" alt="" />
                                </div>

                                <div className="col-lg-2 col-md-2 col-6 text-center py-4 py-sm-0">
                                    <img src={LenovoImage} className="avatar avatar-ex-sm" alt="" />
                                </div>


                                

                                <div className="col-lg-2 col-md-2 col-6 text-center py-4 py-sm-0">
                                    <img src={PaypalImage} className="avatar avatar-ex-sm" alt="" />
                                </div>

                                <div className="col-lg-2 col-md-2 col-6 text-center py-4 py-sm-0">
                                    <img src={ShopifyImage} className="avatar avatar-ex-sm" alt="" />
                                </div>

                                <div className="col-lg-2 col-md-2 col-6 text-center py-4 py-sm-0">
                                    <img src={SpotifyImage} className="avatar avatar-ex-sm" alt="" />
                                </div>
                            </Row>
                        </Container>
                    </section> */}
                    {/* Partners End  */}

                    <Feature />

                    {/* Pricing  */}
                    <Pricing />
                    {/* <Review /> */}
                    {/* <News /> */}
                    <Contact />
                    <Footer />
                </div>
            </React.Fragment>
        )
    }
}

export default Saas;
