import Header from "../components/Header";
import LeftPane from "../components/LeftPane";
import { format, parseISO } from "date-fns";
import { MDBDatatable } from "mdb-react-ui-kit";
import React, { useEffect, useRef, useState } from "react";
import ReactGA from 'react-ga';
import Loader from "../components/Loader";
import { getlogEvent } from "../services/apiServices.js";
import srcImg from "./../components/images/search.svg";
import PageError from "./PageError";

const Eventtracker = () => {
  const [Data, setData] = useState([]);
  const [inputText, setInputText] = useState("");
  const [loading, setLoading] = useState(true)
  const searchRef = useRef();
  const [apiError, setApiError] = useState(false);
  const handleReload = () => {
    window.location.reload();
  }
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (localStorage.getItem("loggedUserInfo")) {
        const response = await getlogEvent(
          JSON.parse(localStorage.getItem("loggedUserInfo")).gymid,
        );
        if (!response) {
          return;
        }
        if (response === "false") {
          setApiError(true)
        } else {


          const usersData = await response.json();
          console.log(usersData);
          if (!usersData) {
            return;
          }
          setData(usersData);
          setLoading(false);
        }
      }
    };
    fetchData();
  }, []);
  let inputHandler = (e) => {
    let lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };
  // const infoData = Data
  const infoData = Data.filter((el) => {
    if (inputText === "") {
      return Data;
    } else {
      console.log((el.Eventtype && el.Eventtype.toLowerCase().includes(inputText)) ||
        (el.flag && el.flag.toLowerCase().includes(inputText)));
      return (
        (el.Eventtype && el.Eventtype.toLowerCase().includes(inputText)) ||
        (el.flag && el.flag.toLowerCase().includes(inputText)) ||
        (el.username1 && el.username1.toLowerCase().includes(inputText))

      );
    }
  });
  let tmrow = [];
  if (localStorage.getItem("loggedUserInfo") && JSON.parse(localStorage.getItem("loggedUserInfo")).role === "staff") {
    let array = infoData.filter((data) => localStorage.getItem("loggedUserInfo") && JSON.parse(localStorage.getItem("loggedUserInfo")).email === data.username1)
    array.forEach((data, index) => {
      let rarray = [];
      rarray.push(index + 1);
      rarray.push(data.username1);
      rarray.push(data.ipAddress);
      rarray.push(data.browserInfo);
      rarray.push(format(parseISO(data.date), "yyyy-MM-dd"));
      rarray.push(format(parseISO(data.date), "hh:mm:ss  aaaaa'm'"));
      rarray.push(data.flag);
      tmrow.push(rarray);
    });
  }
  else {
    infoData.forEach((data, index) => {
      let rarray = [];
      rarray.push(index + 1);
      rarray.push(data.username1);
      rarray.push(data.ipAddress);
      rarray.push(data.browserInfo);
      rarray.push(format(parseISO(data.date), "dd-MM-yyyy"));
      rarray.push(format(parseISO(data.date), "hh:mm:ss  aaaaa'm'"));
      rarray.push(data.flag);
      tmrow.push(rarray);
    });
  }
  const basicData = {
    columns: [
      "S.no",
      "Logged-In User",
      "IP Address",
      "Browser Info",
      "Date",
      "Time",
      "Description",

    ],
    rows: tmrow,
  };
  return (
    <>
      <LeftPane isactive="12" />
      <Header />
      {!apiError && loading && (<h4><Loader /></h4>)}
      {apiError ? <PageError handleReload={handleReload} /> : <div
        className="gym-main rightContent"
      >
        <div
          className="gym-container"
          id="showcase"
        >
          <h3 className="gym-large">Log Events</h3>
          <h3
            className="gym-xlarge gym-text-red"
            style={{ position: "relative" }}
          >
            <input
              type="text"
              ref={searchRef}
              className="gym-input forminput"
              placeholder="search here..."
              onChange={inputHandler}
            />
            <img
              src={srcImg}
              width="20px"
              className="searchicon"
              alt="Search"
            />
          </h3>
        </div>
        <div className="tableContainer logEvent">
          {!loading &&
            <MDBDatatable className="sno"
              // searchLabel="Search"
              //  search
              fixedHeader
              entries={50}
              data={basicData}
            />}
        </div>
      </div>}
    </>
  );
};

export default Eventtracker;
