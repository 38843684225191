import { MDBDatatable, MDBIcon, MDBInputGroup, MDBAlert } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { browserName, browserVersion } from "react-device-detect";
import ReactGA from 'react-ga';
import { useLocation } from "react-router";
import Header from "../components/Header";
import LeftPane from "../components/LeftPane";
import Popup from "../components/Popup";
import CreateStaff from "../components/createStaff";
import { checkStaff, deleteStaff, getCheckStaff, logEvent } from "../services/apiServices";
import deleteImg from "./../components/images/delete.svg";
import editImg from "./../components/images/edit.svg";
import SattendanceList from "./SattendanceList";
import PageError from "./PageError";
import { googleAnalytic } from "../components/googleAnalytics";

const Staff = () => {
  const [userData, setData] = useState([]);
  const [openDetailsPop, setOpenDetailsPop] = useState(false);
  const [selData, setSelData] = useState(false);
  const [checkedStaff, setCheckedStaff] = useState([]);
  const [Error2, setError2] = useState("")
  const [addandSave, setaddandSave] = useState(1);
  const [sId, setSId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const [inputText, setInputText] = useState("");
  const [apiError, setApiError] = useState(false);
  const location = useLocation();

  const handleReload = () => {
    window.location.reload();
  }

  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const errorLimit = () => {
    setError2("You have reached the maximum limit of staff members for this plan. Please upgrade your plan to add more staff members.");
  };
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  function openAddform(e) {
    setSId(0);
    setaddandSave(1);
    togglePopup();
  }
  async function deleteStaffs(tobject) {
    console.log(tobject);
    if (window.confirm("Are you sure want to delete this Staff Member?")) {
      await deleteStaff(tobject._id);
      let ip = localStorage.getItem("ipaddress");
      let browserinfo = browserName + "-" + browserVersion + ".0.0";
      let flag = `Staff Deleted Successfull (${tobject.firstname} ${tobject.lastname})`;
      let Eventtype = "staff";
      let gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
      let username1 = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : "";
      await googleAnalytic("Staff", 'Staff', 'Delete Button', 'Staff Deleted')

      logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, gymid);
      window.location.reload(true);
    }
    return
  }
  let staffRole = localStorage.getItem("loggedUserInfo")
    ? (JSON.parse(localStorage.getItem("loggedUserInfo")).role
      ? JSON.parse(localStorage.getItem("loggedUserInfo")).role : "admin") : "staff";
  function openDetails(obj, data) {
    setSelData(data);
    setSId(obj.id);
    setOpenDetailsPop(true);
  }
  function closeDetails(id) {
    setSId(0);
    setOpenDetailsPop(false);
  }
  function openEditStaff(tId) {
    setSId(tId.id);
    togglePopup();
  }

  function checkandUpdate(staff) {
    if (staff.attendance.length > 0) {
      if (staff.attendance[0].intime && !staff.attendance[0].outtime) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  async function checkinUser(e, obj, checked) {

    let sId = obj._id;
    e.preventDefault();
    checked = checkedStaff.filter((item) => item === sId);
    let chekinflag = checked.length === 0 ? "1" : "0";
    let gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
    const updates = {
      checkFlag: chekinflag,
      sid: sId,
      date: new Date(),
      gymid: gymid,
    };
    let saverep = await checkStaff(updates, sId);
    if (!saverep) {
      return;
    }
    if (saverep === "false") {
      setApiError(true)
    } else {


      await saverep.json();
      //let curUser = usersData.filter((item) => item._id === mId).
      if (chekinflag === "1") {
        setCheckedStaff([...checkedStaff, sId]);
        let ip = localStorage.getItem("ipaddress");
        let browserinfo = browserName + "-" + browserVersion + ".0.0";
        let flag = `Staff Check-In Successfull (${obj.firstname} ${obj.lastname})`;
        let Eventtype = "check-In/Out";
        let username1 = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : "";
        ReactGA.event({
          category: 'Check-In',
          action: "Check-In Button",
          label: "Staff Check-In",
        });
        logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, gymid);
      } else {
        const nusers = checkedStaff.filter((item) => item !== sId);
        setCheckedStaff(nusers);
        let ip = localStorage.getItem("ipaddress");
        let browserinfo = browserName + "-" + browserVersion + ".0.0";
        let flag = `Staff Check-Out Successfull (${obj.firstname} ${obj.lastname})`;
        let Eventtype = "check-In/Out";
        let username1 = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : "";
        logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, gymid);
        ReactGA.event({
          category: 'Check-In',
          action: "Check-Out Button",
          label: "Staff Check-Out",
        });
      }
      localStorage.setItem("setting_save_staff", "Profile saved successfully");
      setData((prevState) => {
        if (prevState.length > 0) {
          const updatedData = prevState.map((entry) => {
            if (entry._id === sId) {
              if (chekinflag === "1") {
                let values = {
                  bId: localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "",
                  createdAt: new Date(),
                  updatedAt: new Date(),
                  date: new Date(),
                  id: sId,
                  intime: new Date(),
                  member_type: "2",
                  _id: sId,
                }
                entry.attendance.unshift(values);
              } else {
                entry.attendance[0].outtime = new Date();
              }
            }
            return entry;
          });
          return updatedData;
        }
        return prevState;
      });
    }
  }
  useEffect(() => {
    async function fetchData() {
      if (localStorage.getItem("loggedUserInfo")) {
        const response = await getCheckStaff(
          JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
        );
        if (!response) {
          return;
        }
        if (response === "false") {
          setApiError(true)
        } else {

          const usersData = await response.json();
          console.log(usersData);
          if (!usersData) {
            return;
          }

          let loggedusers = [];
          let notloggedusers = [];
          let mloggedusers = [];
          let mnotloggedusers = [];
          for (let staff of usersData) {
            if (checkandUpdate(staff)) {
              loggedusers = [...loggedusers, staff._id];
            } else {
              notloggedusers = [...notloggedusers, staff._id];
            }
            const currentTime = new Date();
            const startOfToday = new Date(currentTime);
            startOfToday.setHours(0, 0, 0, 0);
            const endOfToday = new Date(currentTime);
            endOfToday.setHours(23, 59, 59, 999);

            let checkinStaff = staff.attendance.length > 0 ?
              staff.attendance.filter(obj => {
                const intime = new Date(obj.intime);
                return intime >= startOfToday && intime <= endOfToday;
              }) : [];

            if (checkinStaff.length > 0) {
              mloggedusers = [...mloggedusers, staff];
            } else {
              mnotloggedusers = [...mnotloggedusers, staff];
            }
          }
          if (location && location.state && location.state.data !== 2) {
            setData(mloggedusers);
          } else if (location && location.state && location.state.data === 2) {
            setData(mnotloggedusers);
          } else {
            setData(usersData);
          }
          setCheckedStaff(loggedusers);
        }
      }
    }
    fetchData();
    // fetchGymInfo();
  }, [location]);
  const selectedUserIndex = JSON.parse(localStorage.getItem("loggedUserInfo"))
    ? (userData.findIndex((user) => user._id === JSON.parse(localStorage.getItem("loggedUserInfo"))._id)) : 1;
  if (selectedUserIndex !== -1) {
    const selectedUser = userData.splice(selectedUserIndex, 1)[0];
    userData.unshift(selectedUser);
  }
  const usersData = (userData.length > 0 && userData[0] !== undefined) ? userData.filter((el) => {
    if (inputText.trim() === "") {
      return userData;
    } else {
      const trimmedInput = inputText.trim().toLowerCase();
      const fullName = `${el.firstname || ''} ${el.lastname || ''}`.toLowerCase();

      return (
        (el.firstname && el.firstname.toLowerCase().includes(trimmedInput)) ||
        (el.lastname && el.lastname.toLowerCase().includes(trimmedInput)) ||
        (el.number && el.number.toLowerCase().includes(trimmedInput)) ||
        (el.email && el.email.toLowerCase().includes(trimmedInput)) ||
        fullName.includes(trimmedInput)
      );
    }
  }) : [];
  console.log(checkedStaff);
  let tmrow = [];
  if (staffRole === "staff") {
    usersData.forEach((data, index) => {
      let rarray = [];
      rarray.push(index + 1);
      rarray.push(data.firstname + " " + data.lastname);
      rarray.push(data.number);
      rarray.push(data.email);
      rarray.push(<label className="title">{data.role}</label>);
      rarray.push(index === 0 ?
        <button
          type="button"
          className={
            checkedStaff.filter((item) => item === data._id).length > 0
              ? "btn btn-danger  w-120"
              : "btn btn-primary  w-120"
          }
          onClick={(e) => checkinUser(e, data, checkedStaff.filter((item) => item === data._id))}
        >
          {checkedStaff.filter((item) => item === data._id).length > 0
            ? "Check-Out"
            : "Check-In"}
        </button>
        :
        <button
          type="button"
          className={checkedStaff.filter((item) => item === data._id).length > 0
            ? "btn btn-danger  w-120" : "btn btn-primary  w-120"}
        >
          {checkedStaff.filter((item) => item === data._id).length > 0
            ? "Check-Out"
            : "Check-In"}
        </button>

      );
      rarray.push(index === 0 ?
        <>
          <button
            type="button"
            className="button-link ml10" style={{ margin: "17px" }}>
            <img
              src={editImg}
              width="20px"
              alt="Edit"
              onClick={(e) => openEditStaff({ id: data._id })}
            />
          </button>
          <button
            type="button"
            className="button-link ml20">
            <img
              src={deleteImg}
              width="15px"
              alt="Delete"
              onClick={(e) => deleteStaffs(data)}
            />
          </button>
        </>
        :
        <>
          <button
            type="button"
            className="button-link ml10" style={{ margin: "17px" }}>
            <img
              src={editImg}
              width="15px"
              alt="Edit"
            />
          </button>
          <button
            type="button"
            className="button-link ml20">
            <img
              src={deleteImg}
              width="15px"
              alt="Delete"
            />
          </button>
        </>
      );
      rarray.push(index === 0 ?
        <button
          type="button"
          className="button-link link-color" onClick={(e) => openDetails({ id: data._id }, data)}>
          View Attendance
        </button>
        :
        <button
          type="button"
          className="button-link">
          View Attendance
        </button>
      );

      tmrow.push(rarray);
    });
  }
  else {
    usersData.forEach((data, index) => {
      let rarray = [];
      rarray.push(index + 1);
      rarray.push(data.firstname + " " + data.lastname);
      rarray.push(data.number);
      rarray.push(data.email);
      rarray.push(<label className="title">{data.role}</label>);
      rarray.push(
        <button
          type="button"
          className={checkedStaff.filter((item) => item === data._id).length > 0 ? "btn btn-danger  w-120" : "btn btn-primary  w-120"}
          onClick={(e) =>
            checkinUser(
              e,
              data,
              checkedStaff.filter((item) => item === data._id)
            )
          }
        >
          {checkedStaff.filter((item) => item === data._id).length > 0
            ? "Check-Out"
            : "Check-In"}
        </button>
      );
      rarray.push(
        <>
          <button
            type="button"
            className="button-link ml10" style={{ margin: "17px" }}>
            <img
              src={editImg}
              width="15px"
              alt="Edit"
              onClick={(e) => openEditStaff({ id: data._id })}
            />
          </button>
          {(data.role !== "admin") && <button
            type="button"
            className="button-link ml20">
            <img
              src={deleteImg}
              width="15px"
              alt="Delete"
              onClick={(e) => deleteStaffs(data)}
            />
          </button>}
        </>
      );
      rarray.push(
        <button
          type="button"
          className="button-link link-color" onClick={(e) => openDetails({ id: data._id }, data)}>
          View Attendance

        </button>
      );

      tmrow.push(rarray);
    });
  }
  const basicData = {
    columns: ["S.no", "Name", "Contact", "Email", "Role", "Check-In/Check-Outs", "Action", "Attendance"],
    rows: tmrow,
  };
  const plansInfo = localStorage.getItem("planInfo") ? JSON.parse(localStorage.getItem("planInfo")) : null;
  let flagValue = true;
  if (plansInfo != null && (plansInfo.planFeature && !plansInfo.planFeature.stafflogin)) {
    flagValue = false;
  }
  // if (plansInfo != null && usersData.length > 0 && plansInfo.planLimitation.stafflogin != -1) {
  //   let limit = plansInfo.planLimitation.stafflogin;
  //   let staffCount = 0;
  //   staffCount = usersData.length > 0 ? (usersData.filter((obj) => obj.role == 'staff') ? (usersData.filter((obj) => obj.role == 'staff')?.length) : 0) : [];

  //   if (limit <= staffCount) {
  //     flagValue = false;
  //   }
  // }
  return (
    <>
      <LeftPane isactive="6" />
      <Header />
      {apiError ? <PageError handleReload={handleReload} /> :
        <div className="gym-main gym-main-div">
          <div className="gym-container " id="showcase">
            <div className="createplanmain w-100">
              <h3 className="gym-large">Staff Manager</h3>
              {flagValue && <>
              {staffRole === "admin" &&
                  <button
                    type="button"
                    className="button-link link-color p-1 mb-2" id="adduserBtn" onClick={(e) => openAddform(e)} >
                    <div className="createplan">
                      <span className="addic">
                        <MDBIcon fas icon="plus" />
                      </span>
                      <span className="pl-5">Add Staff</span>
                    </div>
                  </button>
                }
              </>}
            </div>

            <MDBInputGroup
              className="mb-3"
              textAfter={<MDBIcon fas icon="search" />}
            >
              <input
                className="form-control gym-input forminput"
                type="text"
                placeholder="search by member"
                onChange={(event) => inputHandler(event)}
              />
            </MDBInputGroup>
          </div>
          <div className="tableContainer">
            <MDBDatatable className="sno" fixedHeader maxHeight="88vh" data={basicData} />
          </div>
        </div>}

      {isOpen && (
        <Popup
          title="Add Staff"
          ptype="modal-lg"
          content={
            <>
              <div>
                <CreateStaff
                  id={null}
                  backallback={togglePopup}
                  submit="0"
                  sId={sId}
                  errorLimit={errorLimit}
                  addandSave={addandSave}
                  userData={userData}
                />
              </div>
            </>
          }
          handleClose={togglePopup}
        />
      )}
      {openDetailsPop && (
        <Popup
          title="Staff Information"
          ptype="modal-lg"
          content={
            <>
              <div className="popupHeight">
                <SattendanceList
                  id={sId}
                  data={selData}
                  oncheckin={checkinUser}
                  onclose={closeDetails}
                  backallback={togglePopup}
                  submit="0"
                />
              </div>
            </>
          }
          handleClose={closeDetails}
        />
      )}
      <MDBAlert
        color='danger'
        autohide
        position='top-right'
        delay={5000}
        appendToBody
        open={Error2 != ""}
        onClose={() => setError2("")}
      >
        {Error2}
      </MDBAlert>
    </>
  );
};

export default Staff;