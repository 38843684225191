import React from 'react';
import { MDBBtn } from 'mdb-react-ui-kit';

function SuccessScreen({ pdfFileLink }) {

    console.log(pdfFileLink)

    const handleDownload = () => {
        if (pdfFileLink) {

            const link = document.createElement('a');
            link.href = pdfFileLink;
            link.download = 'WaiverForm.pdf';
            link.style.display = 'none';


            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);
        } else {
            alert('PDF file link is not available');
        }
    };
   
    return (
        <div className="success-screen">
            <div>

                <div class="success-animation">
                    <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                </div>
                <div className="success-text text-center">
                    <h2 className='mt-2'>Thank you</h2>
                    <h5>Form is submitted successfully!!</h5>
                    <div className="d-flex align-items-center justify-content-center w-100 mt-4 ">
                        <MDBBtn onClick={handleDownload} >
                            Download
                        </MDBBtn>

                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default SuccessScreen;
