import React, { useState, useEffect } from "react";
import { getGBussinessInfo } from "../services/apiServices";
import PageError from "../views/PageError";


export default function Price(props) {
  console.log("----------------------------------------------------------------")
  console.log(props)
  const [data, setData] = useState("");
  const [apiError, setApiError] = useState(false);
  const price = parseFloat((props.price / (1 + (data && data.dgsttax[0] === 1 ? (0.18) : 0)
    + ((data.ugsttax ? data.ugsttax.reduce((acc, obj) => acc + (obj.check ? (parseFloat(obj.label) / 100) : 0), 0) : 0)))).toFixed(2))
  // const dprice = parseFloat((props.Discounts / (1 + (data && data.dgsttax[0] == 1 ? (0.18) : 0)
  // + ((data.ugsttax ? data.ugsttax.reduce((acc, obj) => acc + (obj.check ? (parseFloat(obj.label) / 100) : 0), 0) : 0)))).toFixed(2))
  const dprice = Number(props.Discounts)
  useEffect(() => {
    async function fetchData() {
      if (localStorage.getItem("loggedUserInfo")) {
        const response = await getGBussinessInfo(
          JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
        );
        if (response === "false") {
          setApiError(true)
        } else {
          const record = await response.json();
          if (!record) {
            return;
          }
          setData(record);
        }
      }
    }
    fetchData();
  }, []);
  let pricevalue = price - (props.Discounts ? dprice : 0)

  let tPrice = 0;

  console.log(tPrice)


  console.log(props);
  const totalPrice = Math.round(price * 1 + (data && data.dgsttax[0] === 1 ? (pricevalue * 0.18) : 0) +
    (data.ugsttax ? data.ugsttax.reduce((acc, obj) => acc + (obj.check ? ((pricevalue * 1) * (parseFloat(obj.label) / 100)) : 0), 0) : 0)
    - (props.Discounts ? dprice : 0)

  ).toFixed(2)
  localStorage.setItem("Tprice", totalPrice)

  const handleReload = () => {
    window.location.reload();
  }
  return (
    <>
      {apiError ? <PageError handleReload={handleReload} /> :
        <div className="gym-tax-data">
          <div className="gym-gst ps-0">
            <span>Total</span>
            <span>&#8377;{price.toLocaleString('en-IN')}</span>
          </div>

          <div className="gym-gst ps-0">
            <span>Discount</span>
            <span>
              {/* -{(parseFloat(Number(props.discount) * Number(props.obj.length > 0 ? props.obj[0].quantity : 0)).toFixed(2))} */}
              {props.Discounts ? "-" : ""} &#8377;{props.Discounts ? (dprice).toFixed(2) : 0}
            </span>
          </div>
          {data && ((data.dgsttax[0] === 1 || (data.ugsttax && data.ugsttax.filter((obj) => obj.check === true).length > 0)) &&
            <div className="gym-tax-member">
              <span>TAX </span>
            </div>
          )
          }
          {data && data.dgsttax.map((obj) =>
            <> {obj === 1 &&
              <>
                <div className="gym-tax-member ">
                  <span>{"GST: 18%"}</span>

                  <span>+ &#8377;{(
                    // (((
                    pricevalue
                    // / (1 + (obj == 1 ? (0.18) : 0)
                    //   + ((data.ugsttax ? data.ugsttax.reduce((acc, obj) => acc + ((parseFloat(obj.check ? obj.label : 0) / 100)), 0) : 0))))
                    * 0.18).toFixed(2).toLocaleString('en-IN')
                  }</span>
                </div>
              </>
            }
            </>
          )}
          {data && data.ugsttax.map((obj) =>
            <> {obj.check &&
              <>
                <div className="gym-tax-member">
                  <span>{obj.title}{" "}:{" "}{obj.label}{"%"} </span>
                  <span>+ &#8377;{parseFloat(
                    // ((Math.round(
                    (pricevalue
                      // / (1 + (data && data.dgsttax[0] == 1 ? (0.18) : 0)
                      // + ((data.ugsttax ? data.ugsttax.reduce((acc, obj) => acc + ((parseFloat(obj.check ? obj.label : 0) / 100)), 0) : 0)))))
                      * (parseFloat(obj.label) / 100)
                    ).toFixed(2)
                  ).toLocaleString('en-IN')}
                  </span>
                </div>
              </>
            }
            </>
          )}
          <div className="gym-gst fs-5 fw-600 tax-border-top bg-f6f6f6">
            <span>Grand Total</span>
            <span>&#8377;
              {Math.round(price * 1 + (data && data.dgsttax[0] === 1 ? (pricevalue * 0.18) : 0) +
                (data.ugsttax ? data.ugsttax.reduce((acc, obj) => acc + (obj.check ? ((pricevalue * 1) * (parseFloat(obj.label) / 100)) : 0), 0) : 0)
                - (props.Discounts ? dprice : 0)
                // (parseFloat((props.price * 1) - Number(props.Discounts) * Number(props.obj.length > 0 ? props.obj[0].quantity : 0)).toFixed(2))
                // + (data && data.dgsttax[0] == 1 ? (Math.round((props.price * 1) * 0.18, 2)) : 0)
                // + (data.ugsttax ? data.ugsttax.reduce((acc, obj) => acc + Math.round((props.price * 1) * (parseFloat(obj.check ? obj.label : 0)) / 100), 0) : 0)
              ).toLocaleString('en-IN')}
            </span>
          </div>
        </div>}</>
  );
}
