import React, { Component } from "react";
import { Col, Container, Card, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from 'react-scroll';
import Feature1 from "../assets/images/Checkins.png";
import Feature2 from "../assets/images/PlansAddons.png";
import Leads from "../pages/leads";

class Feature extends Component {


    constructor(props) {
        super(props);
        this.state = {
            services: [
                {
                    id: 'service6',
                    icon: 'expand-arrows-alt',
                    title: "Informative Dashboard with Insights",
                    description: 'Make data-driven decisions with a real-time dashboard that provides actionable insights into member behavior, attendance, and financial performance. Monitor your gym’s health at a glance and make informed choices to boost retention and revenue.'
                },
                {
                    id: 'service5',
                    icon: 'flip-h',
                    title: "Intuitive Point of Sale (PoS)",
                    description: 'Simplify payment processing with an easy-to-use PoS system. Record payments, associate them with memberships, and handle class or service fees efficiently—keeping everything in one place.'
                },
                {
                    id: 'service4',
                    icon: 'fire',
                    title: "Slot-Based Membership Plans",
                    description: 'Offer flexible membership options with different pricing for various time slots. Whether it’s a discounted rate for off-peak hours or a premium package for peak times, customize your offerings to meet demand.'
                },
                {
                    id: 'service1',
                    icon: 'users-alt',
                    title: "Member Management",
                    description: "Stay organized with a comprehensive member management system. Keep track of profiles, membership plans, and attendance records in one easy-to-use platform."
                },
                {
                    id: 'service2',
                    icon: 'signin',
                    title: "Easy Check-in",
                    description: 'Streamline your check-in process with a simple and efficient system that allows members to check in quickly as they arrive at the gym.'
                },
                {
                    id: 'service4',
                    icon: 'mountains',
                    title: "Membership Add-ons",
                    description: ' Increase revenue by offering additional services like personal training, nutritional counseling, or gym merchandise, all of which can be easily added to members’ plans.'
                },





            ],
            from: props.from,
        };
        console.log(props);
    }

    render() {
        const { navigate, planid } = this.props;
        return (
            <React.Fragment>
                {/*  Services START  */}
                <section className="section" id="features">
                    <Container>
                        <Row className="justify-content-center">
                            <div className="col-12">
                                <div className="section-title text-center mb-4 pb-2">
                                    {/* <h3 className="title mb-3">Our Features</h3> */}
                                    <h3 className="title mb-3">Unlock More Value for Your Gym with Our Features in EasyMembr</h3>

                                    <p className="text-muted mx-auto para-desc mb-0">With EasyMembr, you can effortlessly enhance your gym’s offerings and streamline operations. Here’s how:</p>
                                </div>
                            </div>
                        </Row>

                        <Row>
                            {this.state.services.map((item, key) => (
                                <Col lg={4} md={6} className="mt-5 pt-4" key={key}>
                                    <Card className="features feature-primary feature-shadow-md shadow rounded p-4 pt-5 prisingdiv">
                                        <div className="icon-style mt-n80 position-relative text-center shadow rounded bg-white mb-4">
                                            <i className={"h4 mb-0 uil uil-" + item.icon}></i>
                                        </div>
                                        <div className="content">
                                            <Link to="#" className="title text-dark h5">{item.title}</Link>
                                            <p className="text-muted mt-3">{item.description}</p>
                                            {/* <Link to="#" className="text-dark title">Read More <i className="uil uil-arrow-right"></i> </Link> */}
                                        </div>
                                    </Card>
                                </Col>
                            ))}

                        </Row>
                        <p className="d-flex align-items-center justify-content-center text-muted mx-auto para-desc mt-4">
                            <ScrollLink
                                to="pricing"
                                smooth={true}
                                duration={50}
                                className="btn btn-primary primary me-2"
                            >
                                Subscribe Today
                            </ScrollLink>
                        </p>
                        <p className="d-flex align-items-center justify-content-center">
                            <ScrollLink
                                to="contact"
                                smooth={true}
                                duration={50}
                                className="link-color primary"
                                style={{ display: 'inline' }}
                            >
                                click here
                            </ScrollLink>
                            <span style={{ marginLeft: '4px' }}>for a callback from our customer success team</span>
                        </p>
                    </Container>

                    {/* <Container className="mt-100 mt-60">
                        <Row>
                            <Col lg={5}>
                                <div className="section-title">
                                    <h4 className="section-title mb-3 mb-lg-0">Some of the most importent <br /> things in this tools</h4>
                                </div>
                            </Col>

                            <Col lg={7}>
                                <p className="text-muted para-desc mb-0">Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap v5 html page.</p>
                            </Col>
                        </Row>
                    </Container> */}

                    {this.state.from !== "leads" ?
                        <React.Fragment><Container className="mt-100 mt-60">
                            <Row className="align-items-center">
                                <Col lg={6} md={6}>
                                    <img src={Feature1} className="img-fluid shadow-md rounded-md" alt="" />
                                </Col>

                                <Col lg={6} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <div className="section-title ms-lg-5">
                                        <h4 className="title mb-4">Easy Check-in & out Feature</h4>
                                        <p className="text-muted">
                                            EasyMembr offers a seamless user experience with a user friendly interface for tracking footfalls and time spent in the gym for insights to manage your resources better.                                    </p>
                                        <ul className="list-unstyled text-muted">
                                            <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Easy Check-in & out</li>
                                            {/* <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Workout Time Tracking</li> */}
                                        </ul>
                                        {/* <Link to="#" className="mt-3 text-primary">Find Out More <i className="uil uil-angle-right-b"></i></Link> */}
                                    </div>
                                </Col>
                            </Row>
                        </Container>

                            <Container className="mt-100 mt-60">
                                <Row className="align-items-center">
                                    <Col lg={6} md={6} className="order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                        <div className="section-title me-lg-5">
                                            <h4 className="title mb-4">Quick Creation of Slots/Plans/Addons</h4>
                                            <p className="text-muted">
                                                Create membership plans for peak and Non-peak hours, addons without inflating your membership data.                                    </p>
                                            <ul className="list-unstyled text-muted">
                                                {/* <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for Tomorrow</li> */}
                                                {/* <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Create your own Slots/Plans/Addon's</li> */}
                                            </ul>
                                            {/* <Link to="#" className="mt-3 text-primary">Find Out More <i className="uil uil-angle-right-b"></i></Link> */}
                                        </div>
                                    </Col>

                                    <Col lg={6} md={6} className="order-1 order-md-2">
                                        <img src={Feature2} className="img-fluid shadow-md rounded-md" alt="" />
                                    </Col>
                                </Row>
                            </Container>

                        </React.Fragment> : <>

                            <center className="mt-4">
                                <Link
                                    to={`/OtpValidation?id=${planid}&lead=true`}
                                    className="feature-primary btn btn-primary"
                                >
                                    Signup Now
                                </Link>
                            </center>
                        </>
                    }






                </section>

            </React.Fragment>
        );
    }
}
export default Feature;