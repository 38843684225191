export const pricingPlans = [
    {
        _id: "66fe69bcb17e96655b3e16c0",
        name: "Sponsored",
        pricemonthly: "0",
        billingperiod: [6, 12],
        availability: false,
        smsintergration: 400,
        accountantrole: 500,
        whatsappintegration: 500,
        memberportal: 5,
        priceannual: {
            apricemontly: 0,
            discount: "10",
            fpricemontly: 0,
        }
    },
    {
        _id: "66fe69bcb17e96655b3e16c7",
        name: "Free Starter",
        pricemonthly: "0",
        billingperiod: [1],
        availability: true,
        smsintergration: 500,
        accountantrole: 500,
        whatsappintegration: 500,
        memberportal: 5,
        priceannual: {
            apricemontly: 0,
            discount: "0",
            fpricemontly: 0,
        }
    },
    {
        _id: "66fe69bcb17e96655b3e16cd",
        name: "Lean",
        pricemonthly: "1140",
        billingperiod: [6, 12],
        availability: true,
        smsintergration: 400,
        accountantrole: 500,
        whatsappintegration: 500,
        memberportal: 5,
        priceannual: {
            apricemontly: 1140,
            discount: "10",
            fpricemontly: 1026,
        }
    },
    {
        _id: "66fe69bcb17e96655b3e16d3",
        name: "Pro",
        pricemonthly: "1425",
        billingperiod: [6, 12],
        availability: true,
        smsintergration: 300,
        accountantrole: 500,
        whatsappintegration: 500,
        memberportal: 5,
        priceannual: {
            apricemontly: 1425,
            discount: "10",
            fpricemontly: 1283,
        }
    },
    {
        _id: "66fe69bcb17e96655b3e16d9",
        name: "Premium",
        pricemonthly: "1900",
        billingperiod: [6, 12],
        availability: true,
        smsintergration: 500,
        accountantrole: 500,
        whatsappintegration: 500,
        memberportal: 5,
        priceannual: {
            apricemontly: 1900,
            discount: "10",
            fpricemontly: 1710,
        }
    }
]



export const plansFeature = [
    {
        _id: "66fe69bcb17e96655b3e16c3",
        id: "66fe69bcb17e96655b3e16c0",
        dashboard: true,
        membercheckin: true,
        checkinreports: true,
        memebershipplans: true,
        addons: true,
        timeslots: true,
        planswithsessionslimit: true,
        adminlogin: true,
        stafflogin: true,
        discountplans: true,
        emailreceipt: true,
        digitalwaiver: true,
        pos: true,
        gstreceipts: true,
        trainersessionssummary: true,
        loginwithgoogleaccount: true,
        paymentsummary: false,
        plansummary: false,
        sessionsummary: false,
        sessioncount: false,
        revenuereport: false,
        activememberhistory: false,
    },
    {
        _id: "66fe69bcb17e96655b3e16c9",
        id: "66fe69bcb17e96655b3e16c7",
        dashboard: true,
        membercheckin: true,
        checkinreports: true,
        memebershipplans: true,
        addons: true,
        timeslots: true,
        planswithsessionslimit: false,
        adminlogin: true,
        stafflogin: false,
        discountplans: false,
        emailreceipt: false,
        digitalwaiver: false,
        pos: true,
        gstreceipts: true,
        trainersessionssummary: true,
        loginwithgoogleaccount: true,
        plansummary: false,
        paymentsummary: false,
        sessionsummary: false,
        sessioncount: false,
        revenuereport: false,
        activememberhistory: false,
    },
    {
        _id: "66fe69bcb17e96655b3e16cf",
        id: "66fe69bcb17e96655b3e16cd",
        dashboard: true,
        membercheckin: true,
        checkinreports: true,
        memebershipplans: true,
        addons: true,
        timeslots: true,
        planswithsessionslimit: false,
        adminlogin: true,
        stafflogin: true,
        discountplans: true,
        emailreceipt: true,
        digitalwaiver: true,
        pos: true,
        gstreceipts: true,
        trainersessionssummary: true,
        loginwithgoogleaccount: true,
        paymentsummary: false,
        plansummary: false,
        sessionsummary: false,
        sessioncount: false,
        revenuereport: false,
        activememberhistory: false,
    },
    {
        _id: "66fe69bcb17e96655b3e16d5",
        id: "66fe69bcb17e96655b3e16d3",
        dashboard: true,
        membercheckin: true,
        checkinreports: true,
        memebershipplans: true,
        addons: true,
        timeslots: true,
        planswithsessionslimit: true,
        adminlogin: true,
        stafflogin: true,
        discountplans: true,
        emailreceipt: true,
        digitalwaiver: true,
        pos: true,
        gstreceipts: true,
        trainersessionssummary: true,
        loginwithgoogleaccount: true,
        paymentsummary: true,
        plansummary: true,
        sessionsummary: true,
        sessioncount: true,
        revenuereport: false,
        activememberhistory: false,
    },
    {
        _id: "66fe69bcb17e96655b3e16db",
        id: "66fe69bcb17e96655b3e16d9",
        dashboard: true,
        membercheckin: true,
        checkinreports: true,
        memebershipplans: true,
        addons: true,
        timeslots: true,
        planswithsessionslimit: true,
        adminlogin: true,
        stafflogin: true,
        discountplans: true,
        emailreceipt: true,
        digitalwaiver: true,
        pos: true,
        gstreceipts: true,
        trainersessionssummary: true,
        loginwithgoogleaccount: true,
        paymentsummary: true,
        plansummary: true,
        sessionsummary: true,
        sessioncount: true,
        revenuereport: true,
        activememberhistory: true,
    }
]


export const planLimitation = [
    {
        _id: "66fe69bcb17e96655b3e16c5",
        id: "66fe69bcb17e96655b3e16c0",
        memebershipplans: 20,
        addons: 10,
        timeslots: -1,
        adminlogin: 5,
        stafflogin: 5,
        activemembers: 100,
        transactions: -1,
    },
    {
        _id: "66fe69bcb17e96655b3e16cb",
        id: "66fe69bcb17e96655b3e16c7",
        memebershipplans: 1,
        addons: 0,
        timeslots: 1,
        stafflogin: 0,
        adminlogin: 1,
        activemembers: 20,
        transactions: 30,
    },
    {
        _id: "66fe69bcb17e96655b3e16d1",
        id: "66fe69bcb17e96655b3e16cd",
        memebershipplans: 10,
        addons: 5,
        timeslots: 3,
        stafflogin: 5,
        adminlogin: 3,
        activemembers: 50,
        transactions: 100,
    },
    {
        _id: "66fe69bcb17e96655b3e16d7",
        id: "66fe69bcb17e96655b3e16d3",
        memebershipplans: 50,
        addons: 50,
        timeslots: 5,
        adminlogin: 5,
        stafflogin: 10,
        activemembers: 100,
        transactions: 1000,
    },
    {
        _id: "66fe69bcb17e96655b3e16dd",
        id: "66fe69bcb17e96655b3e16d9",
        memebershipplans: 100,
        addons: 100,
        timeslots: -1,
        stafflogin: -1,
        adminlogin: -1,
        activemembers: -1,
        transactions: -1,
    }
];

