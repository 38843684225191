import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import PasswordStrengthChecker from "../components/PasswordValidator";
import { isEmpty } from "../validators/helper.js";
import { generateNewPassword } from "../services/apiServices";
import { MDBIcon } from "mdb-react-ui-kit";

const GenerateNewPassword = ({ onSuccess }) => {
    const [error, setError] = useState({});
    const [strongPassword, setstrongPassword] = useState(false);
    const [signupForm, setSignupForm] = useState({
        password: "",
        confirmpassword: "",
    });


    const [isPopupOpen, setIsPopupOpen] = useState(true);

    const validateForm = () => {
        let errors = {};

        if (isEmpty(signupForm.password)) {
            errors.password = "Password can't be blank";
        } else if (signupForm.password.length < 8) {
            errors.password = "Password must be at least 8 characters long.";
        } else if (!strongPassword.istrong) {
            errors.password = "Please enter a strong password!";
        }

        if (signupForm.password !== signupForm.confirmpassword) {
            errors.confirmpassword = "Passwords do not match";
        }

        setError(errors);
        return isEmpty(errors);
    };

    const staffId = JSON.parse(localStorage.getItem("loggedUserInfo"))?._id;

    const submitHandler = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            const updates = { password: signupForm.password, staffid: staffId };

            try {
                const response = await generateNewPassword(updates);
                if (!response || response === "false") {
                    throw new Error("Failed to change password.");
                }

                const result = await response.json();
                console.log("Response:", result);


                setIsPopupOpen(false);
                // setTimeout(() => {
                //     onSuccess(); 
                //   },1000);
                onSuccess();


            } catch (err) {
                console.error("Error:", err);

            }
        }
    };

    function setPasswordStatus(tflag) {
        setstrongPassword(tflag);
    }

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible((prevState) => !prevState);
    };
    const updateForm = (value) =>
        setSignupForm((prev) => ({ ...prev, ...value }));

    return (
        <React.Fragment>
            {isPopupOpen &&

                (
                    <div className="d-flex align-items-center justify-content-center">
                        <form className="login-form" onSubmit={submitHandler}>
                            <Row>

                                <Col lg={12}>
                                    <PasswordStrengthChecker
                                        setPasswordInput={updateForm}
                                        setPasswordStatus={setPasswordStatus}
                                    />
                                    {error.password && (
                                        <p className="clr-red">{error.password}</p>
                                    )}
                                </Col>
                                <Col lg={12}>
                                    <div className="mb-3">
                                        <label className="form-label">
                                            Confirm Password
                                            <span className="text-danger">*</span>
                                        </label>
                                        <div className="IconContainer">
                                            <input
                                                type={isPasswordVisible ? "text" : "password"}
                                                className="form-control"
                                                placeholder="Confirm Password"
                                                onChange={(e) =>
                                                    updateForm({
                                                        confirmpassword: e.target.value,
                                                    })
                                                }
                                                value={signupForm.confirmpassword}
                                                required
                                            />
                                            <MDBIcon fas icon={isPasswordVisible ? "eye-slash" : "eye"} className="inputIcon" onClick={togglePasswordVisibility} />

                                        </div>
                                        {error.confirmpassword && (
                                            <p className="clr-red">
                                                {error.confirmpassword}
                                            </p>
                                        )}
                                    </div>
                                </Col>


                            </Row>
                            <button
                                type="submit"
                                className="btn btn-primary w-100 mt-3"
                            // disabled={signupForm.password !== signupForm.confirmpassword}
                            >
                                Submit
                            </button>
                        </form>
                    </div>
                )

            }
        </React.Fragment >
    );
};

export default GenerateNewPassword;
