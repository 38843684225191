import React, { useEffect, useState } from "react";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { isEmail, isEmpty } from "../validators/helper.js";
import ReactGA from 'react-ga';
import {
    CountryDropdown,
    RegionDropdown
} from "react-country-region-selector";
import { MDBModalFooter, MDBRow, MDBCol, MDBAlert, } from 'mdb-react-ui-kit';

import { creategyms, getexistgym } from "../services/apiServices.js"


export default function AddGym({ onSuccess }) {


    const [country, setCountry] = useState("India");
    const [region, setRegion] = useState("Telangana");
    const [error, setError] = useState({});
    const [Error2, setError2] = useState("")
    const [businessForm, setbusinessForm] = useState({
        businessName: "",
        businessEmail: "",
        firstName: "",
        lastName: "",
        contactNumber: "",
        state: "",
        city: "",
        address: "",
    });

    async function handleClick() {
        try {

            window.location.href = "/settingview";
        } catch (e) {

        }
    }


    let validateForm = (e) => {
        console.log("Validating form...");
        let errors = {};
        if (isEmpty(businessForm.businessName)) {
            errors.businessName = "Business Name can't be blank";
        }
        if (isEmpty(businessForm.businessEmail)) {
            errors.businessEmail = "Email can't be blank";
        } else if (!isEmail(businessForm.businessEmail)) {
            errors.businessEmail = "Please enter valid email";
        }
        if (isEmpty(businessForm.firstName)) {
            errors.firstName = "First Name can't be blank";
        }
        if (isEmpty(businessForm.lastName)) {
            errors.lastName = "Last Name can't be blank";
        }
        if (isEmpty(businessForm.contactNumber)) {
            errors.contactNumber = "Contact Number can't be blank";
        } else if (!isValidPhoneNumber(businessForm.contactNumber)) {
            errors.contactNumber = "Please enter valid Contact Number";
        }

        if (isEmpty(businessForm.city)) {
            errors.city = "City can't be blank";
        }
        if (isEmpty(businessForm.address)) {
            errors.address = "Address can't be blank";
        }
        if (isEmpty(errors)) {
            return true;
        } else {
            return errors;
        }
    };

    function updateForm(value) {
        return setbusinessForm((prev) => {
            return { ...prev, ...value };
        });
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);


    async function onSubmit(e) {
        e.preventDefault();

        let errors = validateForm(e);
        if (errors === true) {

            const data = {
                businessName: businessForm.businessName,
                email: businessForm.businessEmail,
                contactNumber: businessForm.contactNumber,
                city: businessForm.city,
                firstName: businessForm.firstName,
                lastName: businessForm.lastName,
            };

            try {
                const checkResponse = await getexistgym(data);

                if (!checkResponse.ok) {
                    if (checkResponse.status === 404) {
                        setError2("Gym does not exist with the provided credentials.");
                    } else {
                        throw new Error(`Error: ${checkResponse.status} - ${checkResponse.statusText}`);
                    }
                    return;
                }

                const validationResult = await checkResponse.json();
                if (validationResult.fieldErrors && Object.keys(validationResult.fieldErrors).length > 0) {
                    setError(validationResult.fieldErrors);
                    return;
                }

                const gymid = JSON.parse(localStorage.getItem("loggedUserInfo")).gymid;

                const updates = {
                    businessName: businessForm.businessName,
                    email: businessForm.businessEmail,
                    contactNumber: businessForm.contactNumber,
                    parentgym: gymid,
                };

                const response = await creategyms(updates);

                setError("")

                if (!response.ok) {
                    throw new Error(`Error: ${response.status} - ${response.statusText}`);
                }

                const res = await response.json();

                // console.log("Gym creation response:", res);

                if (response.ok) {
                    onSuccess(businessForm.businessEmail)

                }


            } catch (error) {
                console.error("Error during gym creation process:", error);

                if (!Error2) {
                    setError2("This gym is already added.");
                }
            }
        } else {

            setError(errors);
        }
    }

    return (
        <>
            <div className="gym-container">
                <MDBAlert
                    color={(Error2.includes("internet") || Error2.includes("wrong")) ? "danger" : (Error2.includes("submitted") || Error2.includes("credentials") ? "danger" : "danger")}
                    autohide
                    position="top-right"
                    delay={5000}
                    appendToBody
                    open={Error2 !== ""}
                    onClose={() => setError2("")}
                >
                    {Error2}
                </MDBAlert>
                <form
                    action="#"
                    target="_blank"
                    className="business-form"
                    onSubmit={onSubmit}
                >
                    <div className="popup-bussiness">

                        <MDBRow>
                            <MDBCol sm={12} md={6} lg={4}>
                                <div className="mt-2">
                                    <label>Business Name<span className="text-danger">*</span></label>
                                    <input
                                        className="gym-input"
                                        type="text"
                                        name="bussiness_name"
                                        id="bussiness_name"
                                        placeholder="Busniess Name"
                                        value={businessForm.businessName}
                                        maxLength="30"
                                        onChange={(e) => updateForm({ businessName: e.target.value })}
                                    />
                                    {error.businessName && (
                                        <>
                                            <p className="clr-red">{error.businessName}</p>
                                        </>
                                    )}
                                </div>
                            </MDBCol>
                            <MDBCol sm={12} md={6} lg={4}>
                                <div className="mt-2">
                                    <label>Email<span className="text-danger">*</span></label>
                                    <input
                                        className="gym-input"
                                        type="text"
                                        name="business_email"
                                        id="business_email"
                                        required=""
                                        placeholder="Email"
                                        value={businessForm.businessEmail}
                                        onChange={(e) => updateForm({ businessEmail: e.target.value })}
                                    />
                                    {error.businessEmail && (
                                        <>
                                            <p className="clr-red">{error.businessEmail}</p>
                                        </>
                                    )}
                                </div>
                            </MDBCol>
                            <MDBCol sm={12} md={6} lg={4}>
                                <div className="mt-2">
                                    <label>Contact No.<span className="text-danger">*</span></label>
                                    <PhoneInput
                                        className="gym-input d-flex"
                                        defaultCountry="IN"
                                        placeholder="Enter phone number"
                                        value={businessForm.contactNumber}
                                        // onInput={(e) => e.target.value = e.target.value.slice(0, 15)}
                                        onChange={(value) => updateForm({ contactNumber: value })}
                                    />
                                    {error.contactNumber && (
                                        <>
                                            <p className="clr-red">{error.contactNumber}</p>
                                        </>
                                    )}
                                </div>
                            </MDBCol>
                            <MDBCol sm={12} md={6} lg={4}>
                                <div className="mt-2">
                                    <label>Country</label>
                                    <CountryDropdown
                                        className="gym-input"
                                        value={country}
                                        onChange={(val) => setCountry(val)}
                                    />
                                </div>
                            </MDBCol>
                            <MDBCol sm={12} md={6} lg={4}>
                                <div className="mt-2">
                                    <label>State</label>

                                    <RegionDropdown
                                        className="gym-input"
                                        country={country}
                                        value={region}
                                        onChange={(val) => setRegion(val)}
                                    />
                                </div>
                            </MDBCol>
                            <MDBCol sm={12} md={6} lg={4}>
                                <div className="mt-2">
                                    <label>City<span className="text-danger">*</span></label>
                                    <input
                                        className="gym-input"
                                        type="text"
                                        name="city"
                                        id="city"
                                        required=""
                                        placeholder="City"
                                        value={businessForm.city}
                                        onChange={(e) => updateForm({ city: e.target.value })}
                                    />
                                    {error.city && (
                                        <>
                                            <p className="clr-red">{error.city}</p>
                                        </>
                                    )}
                                </div>
                            </MDBCol>
                            <MDBCol sm={12} md={6} lg={4}>
                                <div className="mt-2">
                                    <label> First Name<span className="text-danger">*</span></label>
                                    <input
                                        className="gym-input"
                                        type="text"
                                        name="first_name"
                                        id="first_name"
                                        required=""
                                        placeholder="First Name"
                                        value={businessForm.firstName}
                                        onChange={(e) => updateForm({ firstName: e.target.value })}
                                    />
                                    {error.firstName && (
                                        <>
                                            <p className="clr-red">{error.firstName}</p>
                                        </>
                                    )}
                                </div>
                            </MDBCol>
                            <MDBCol sm={12} md={6} lg={4}>
                                <div className="mt-2">
                                    <label> Last Name<span className="text-danger">*</span></label>
                                    <input
                                        className="gym-input"
                                        type="text"
                                        name="last_name"
                                        id="last_name"
                                        required=""
                                        placeholder="Last Name"
                                        value={businessForm.lastName}
                                        onChange={(e) => updateForm({ lastName: e.target.value })}
                                    />
                                    {error.lastName && (
                                        <>
                                            <p className="clr-red">{error.lastName}</p>
                                        </>
                                    )}
                                </div>
                            </MDBCol>
                            <MDBCol sm={12} md={6} lg={4}>
                                <div className="mt-2">
                                    <label>Business Address<span className="text-danger">*</span></label>
                                    <input
                                        className="gym-input "
                                        type="text"
                                        name="address"
                                        id="address"
                                        required=""
                                        placeholder="Address"
                                        value={businessForm.address}
                                        onChange={(e) => updateForm({ address: e.target.value })}
                                    />
                                    {error.address && (
                                        <>
                                            <p className="clr-red">{error.address}</p>
                                        </>
                                    )}
                                </div>

                            </MDBCol>
                        </MDBRow>
                    </div>
                    <MDBModalFooter>
                        <button
                            className="btn btn-primary me-2"
                            data-mdb-dismiss="modal"
                            type="submit"
                        >
                            Save
                        </button>
                        <button
                            className="btn btn-secondary closegym"
                            data-mdb-dismiss="modal"
                            type="button"
                            onClick={handleClick}
                        >
                            Close
                        </button>
                    </MDBModalFooter>
                </form>
            </div>
        </>
    );
}