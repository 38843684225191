import React, { Component } from "react";
import { Col, Container, Card, Row } from "reactstrap";
import { Link } from "react-router-dom";

import Navbar from "../../component/Navbar";
import Pricing from "../../component/Pricing";
// import Review from "../../component/Review";
// import News from "../../component/News";
import Contact from "../../component/Contact";
import Footer from "../../component/Footer";
import Feature from "../../component/Feature";
// import Privacy from "../../../components/privacy";
import { initialize, pageview } from 'react-ga';
// import AmazonImage from '../../assets/images/client/amazon.svg';
// import GoogleImage from '../../assets/images/client/google.svg';
// import LenovoImage from '../../assets/images/client/lenovo.svg';
// import PaypalImage from '../../assets/images/client/paypal.svg';
// import ShopifyImage from '../../assets/images/client/shopify.svg';
// import SpotifyImage from '../../assets/images/client/spotify.svg';
// import LogoIcon from '../../assets/images/logo-light.png';
import { Link as ScrollLink } from 'react-scroll'; // Import react-scroll Link
import Laptop from '../../assets/images/bg/laptop.png';
import { MDBAlert, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { getAllPricingPlans } from "../../../services/apiServices";
// import Feature1 from "../assets/images/Checkins.png";
// import Feature2 from "../assets/images/PlansAddons.png";
import Feature1 from "../../assets/images/Checkins.png";
import Feature2 from "../../assets/images/PlansAddons.png";
import {
    NavbarBrand,

} from "reactstrap";

import logodark from "../../assets/images/Easy-MembrLogo.png";
import logolight from "../../assets/images/Easy-MembrLogo.png";
import Logo from "../../assets/images/Easy-MembrLogo.png";
// import Privacy from "../../components/privacy";
// import Terms from "../../components/terms.js";
// import Faqs from "../../components/faqs.js";
import Privacy from "../../../components/privacy";
import Terms from "../../../components/terms.js";
import Faqs from "../../../components/faqs.js";
import Popup from "../../../components/Popup";
import * as Icon from 'react-feather';
import { customPremium } from "../../../services/apiServices";
import Leadsignup from "./signup";

class Leads extends Component {

    constructor(props) {
        super(props);
        this.state = {
            services: [
                {
                    id: 'service6',
                    icon: 'expand-arrows-alt',
                    title: "Informative Dashboard with Insights",
                    description: 'Make data-driven decisions with a real-time dashboard that provides actionable insights into member behavior, attendance, and financial performance. Monitor your gym’s health at a glance and make informed choices to boost retention and revenue.'
                },
                {
                    id: 'service5',
                    icon: 'flip-h',
                    title: "Intuitive Point of Sale (PoS)",
                    description: 'Simplify payment processing with an easy-to-use PoS system. Record payments, associate them with memberships, and handle class or service fees efficiently—keeping everything in one place.'
                },
                {
                    id: 'service4',
                    icon: 'fire',
                    title: "Slot-Based Membership Plans",
                    description: 'Offer flexible membership options with different pricing for various time slots. Whether it’s a discounted rate for off-peak hours or a premium package for peak times, customize your offerings to meet demand.'
                },
                {
                    id: 'service1',
                    icon: 'users-alt',
                    title: "Member Management",
                    description: "Stay organized with a comprehensive member management system. Keep track of profiles, membership plans, and attendance records in one easy-to-use platform."
                },
                {
                    id: 'service2',
                    icon: 'signin',
                    title: "Easy Check-in",
                    description: 'Streamline your check-in process with a simple and efficient system that allows members to check in quickly as they arrive at the gym.'
                },
                {
                    id: 'service4',
                    icon: 'mountains',
                    title: "Membership Add-ons",
                    description: ' Increase revenue by offering additional services like personal training, nutritional counseling, or gym merchandise, all of which can be easily added to members’ plans.'
                },





            ],
            from: props.from,
            plansData: null,
            firstName: "",
            email: "",
            phone: "",
            inquiry: "",
            toggleMenu: false,
            logourl: localStorage.getItem("loggedUserInfo") ? (JSON.parse(localStorage.getItem("loggedUserInfo")).gymlogoUrl || logodark) : logodark,
            imageLoaded: true,
            arrow: false,
            popup: false,
            popup1: false,
            popup2: false,
            alerttxt: "",
            status: "",
            errors: {
                firstName: "",
                email: "",
                phone: "",
                inquiry: "",
            },
            // logourl: localStorage.getItem("loggedUserInfo") ? (JSON.parse(localStorage.getItem("loggedUserInfo")).gymlogoUrl || Logo) : Logo,
            // imageLoaded: true
        };
        window.addEventListener("scroll", this.windowScroll);
        console.log(props);
    }



    /**
     * Window scroll 
     */

    fetchData = async () => {
        const response = await getAllPricingPlans();
        if (!response || response === "false") {
            return;
        }
        const recinfo = await response.json();
        this.setState({ plansData: recinfo });
    };

    windowScroll() {
        const navbar = document.getElementById("navbar");
        if (navbar) {
            if (
                document.body.scrollTop >= 50 ||
                document.documentElement.scrollTop >= 50
            ) {
                navbar.classList.add("nav-sticky");
            } else {
                navbar.classList.remove("nav-sticky");
            }
        }
    }

    componentDidMount() {
        initialize("G-HWWFT9073S");
        pageview("/landingPage");
        this.fetchData()
    }


    handleSubmit = async (e) => {
        e.preventDefault();
        const { firstName, email, phone, inquiry, errors } = this.state;

        // Validate all fields before submission
        if (!this.validateForm()) return;

        const updates = {
            fname: firstName,
            email: email,
            number: phone,
            inquiry: inquiry,
            from: "leads",
        };

        try {
            const response = await customPremium(updates);
            if (response.status === 200) {
                // Reset the form
                this.setState({
                    firstName: "",
                    email: "",
                    phone: "",
                    inquiry: "",
                    errors: {
                        firstName: "",
                        email: "",
                        phone: "",
                        inquiry: "",
                    },

                });
                this.setState({ alerttxt: "Your inquiry has been submitted successfully!", status: "success" })

            } else {
                this.setState({ alerttxt: "There was an issue submitting your inquiry. Please try again.", status: "danger" })

            }
        } catch (error) {
            console.error("Error submitting form: ", error);
            // alert("An error occurred. Please try again later.");
            this.setState({ alerttxt: "An error occurred. Please try again later.", status: "danger" })
        }
    };

    handleChange = (e) => {
        const { id, value } = e.target;
        this.setState({ [id]: value }, () => this.validateField(id, value));
    };

    validateField = (fieldName, value) => {
        let errors = { ...this.state.errors };

        switch (fieldName) {
            case "firstName":
                errors.firstName = value.trim() === "" ? "First name is required." : "";
                break;
            case "email":
                errors.email = /\S+@\S+\.\S+/.test(value) ? "" : "Enter a valid email address.";
                break;
            case "phone":
                errors.phone =
                    value.trim() === "" || value.length < 10
                        ? "Enter a valid 10-digit phone number."
                        : "";
                break;
            case "inquiry":
                errors.inquiry = value.trim() === "" ? "Inquiry message is required." : "";
                break;
            default:
                break;
        }

        this.setState({ errors });
    };

    validateForm = () => {
        const { firstName, email, phone, inquiry } = this.state;

        // Trigger validation for all fields
        this.validateField("firstName", firstName);
        this.validateField("email", email);
        this.validateField("phone", phone);
        this.validateField("inquiry", inquiry);

        const { errors } = this.state;

        // Check if there are any errors
        return !Object.values(errors).some((error) => error !== "");
    };

    handleImageLoad = () => {
        this.setState({ imageLoaded: true });
    };

    handleImageError = () => {
        this.setState({ imageLoaded: false });
    };

    // componentDidMount() {
    //     window.addEventListener('scroll', this.handleScroll);
    // }

    // componentWillUnmount() {
    //     window.removeEventListener('scroll', this.handleScroll);
    // }

    // handleScroll() {
    //     if (document.body.scrollTop >= 500 || document.documentElement.scrollTop >= 500) {
    //         this.setState({ arrow: true });
    //     } else {
    //         this.setState({ arrow: false });
    //     }
    // }

    compopup = () => {
        this.setState({ popup: true })
    }

    handler = () => {
        this.setState({ popup: false })
    }

    compopup1 = () => {
        this.setState({ popup1: true })
    }

    handler1 = () => {
        this.setState({ popup1: false })
    }

    compopup2 = () => {
        this.setState({ popup2: true })
    }

    handler2 = () => {
        this.setState({ popup2: false })
    }

    handleImageLoad = () => {
        this.setState({ imageLoaded: true });
    };

    handleImageError = () => {
        this.setState({ imageLoaded: false });
    };


    handleralert = () => {
        this.setState({ alerttxt: "", status: "" })
    }
    render() {
        const { plansData, arrow, popup, popup1, popup2 } = this.state;
        const { logourl, imageLoaded } = this.state;
        const { firstName, email, phone, inquiry, errors, alerttxt } = this.state;
        const planid = plansData?.pricingPlans.find((obj) => obj.name === "Free Starter")?._id;

        return (

            <React.Fragment>
                <div>
                    <nav id="navbar" className="navbar navbar-expand-lg fixed-top sticky align-items-center justify-content-center">


                        <NavbarBrand to="/">
                            {imageLoaded ? (
                                <>
                                    <div className="d-flex align-items-center position-relative">
                                        <img src={logourl} alt="Logo" className="newLogo" onLoad={this.handleImageLoad} onError={this.handleImageError} />
                                        <div className="text-dark fs-6 ByZencruzText" >By Zencruz</div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="d-flex align-items-center position-relative">
                                        <img src={logodark} alt="Default Logo" className="newLogo" />
                                        <div className="text-dark fs-6 ByZencruzText" >By Zencruz</div>
                                    </div>
                                </>

                            )}

                        </NavbarBrand>
                    </nav>
                    {/******Feature start*************/}
                    <React.Fragment>
                        {/*  Services START  */}
                        <section className="section mt-4" id="features">
                            <Container className="section mt-4">
                                <Row className="justify-content-center">
                                    <div className="col-12">
                                        <div className="section-title text-center mb-4 pb-2">
                                            {/* <h3 className="title mb-3">Our Features</h3> */}
                                            <h3 className="title mb-3">Unlock More Value for Your Gym with Our Features in EasyMembr</h3>

                                            <p className="text-muted mx-auto para-desc mb-0">With EasyMembr, you can effortlessly enhance your gym’s offerings and streamline operations. Here’s how:</p>
                                        </div>
                                    </div>
                                </Row>

                                <Row>
                                    {this.state.services.map((item, key) => (
                                        <Col lg={4} md={6} className="mt-5 pt-4" key={key}>
                                            <Card className="features feature-primary feature-shadow-md shadow rounded p-4 pt-5 prisingdiv">
                                                <div className="icon-style mt-n80 position-relative text-center shadow rounded bg-white mb-4">
                                                    <i className={"h4 mb-0 uil uil-" + item.icon}></i>
                                                </div>
                                                <div className="content">
                                                    <Link to="#" className="title text-dark h5">{item.title}</Link>
                                                    <p className="text-muted mt-3">{item.description}</p>
                                                    {/* <Link to="#" className="text-dark title">Read More <i className="uil uil-arrow-right"></i> </Link> */}
                                                </div>
                                            </Card>
                                        </Col>
                                    ))}

                                </Row>

                            </Container>

                            {/* <Container className="mt-100 mt-60">
                        <Row>
                            <Col lg={5}>
                                <div className="section-title">
                                    <h4 className="section-title mb-3 mb-lg-0">Some of the most importent <br /> things in this tools</h4>
                                </div>
                            </Col>

                            <Col lg={7}>
                                <p className="text-muted para-desc mb-0">Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap v5 html page.</p>
                            </Col>
                        </Row>
                    </Container> */}



                            {/* <center className="mt-4">
                                <Link
                                    to={`/OtpValidation?id=${planid}&lead=${true}`}
                                    className="feature-primary btn btn-primary"
                                >
                                    Signup Now
                                </Link>
                            </center> */}

                        </section>

                    </React.Fragment>

                    {/******Feature end*************/}
                    <React.Fragment>
                        <section className="" id="contact">
                            <Container>
                                {/* <Row className="justify-content-center">
                                    <Col>
                                        <div className="section-title text-center pb-2">
                                            <h4 className="title mb-3">Get In Touch!</h4>

                                        </div>
                                    </Col>
                                </Row> */}
                                {/* <Row className="align-items-center"> */}
                                {/* <Col lg={6} md={4} className="order-md-2 order-1 mt-4 pt-2"> */}

                                {/* <div className="p-4 rounded shadow bg-white">
                                            <h5>Contact Us</h5>
                                            <form
                                                id="contactForm"
                                                className="login-form"
                                                onSubmit={this.handleSubmit}
                                            >
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label className="form-label">
                                                                First Name <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="form-icon position-relative">
                                                                <input
                                                                    type="text"
                                                                    id="firstName"
                                                                    className="form-control"
                                                                    placeholder="First Name"
                                                                    value={firstName}
                                                                    onChange={this.handleChange}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label className="form-label">
                                                                Your Email <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="form-icon position-relative">
                                                                <input
                                                                    type="email"
                                                                    id="email"
                                                                    className="form-control"
                                                                    placeholder="Email"
                                                                    value={email}
                                                                    onChange={this.handleChange}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label className="form-label">
                                                                Phone No. <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="form-icon position-relative">

                                                                <input
                                                                    type="number"
                                                                    id="phone"
                                                                    className="form-control"
                                                                    placeholder="Phone No."
                                                                    value={phone}
                                                                    onChange={this.handleChange}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label className="form-label">
                                                                Inquiry <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="form-icon position-relative">
                                                                <textarea
                                                                    id="inquiry"
                                                                    className="form-control"
                                                                    placeholder="Enter Your Query..."
                                                                    value={inquiry}
                                                                    onChange={this.handleChange}
                                                                    required
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="d-grid">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                            >
                                                                Send
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>*/}
                                {/* </Col>  */}
                                {/* <Col lg={6} md={4} className="col-12 order-md-1 order-2 mt-4 pt-2"> */}
                                {/* <div className="me-lg-4">
                                            <div className="d-flex">
                                                <div className="icons text-center">
                                                    <i className="uil uil-phone d-block rounded h4 mb-0"></i>
                                                </div>
                                                <div className="flex-1 ms-3">
                                                    <h5 className="mb-2">Phone</h5>
                                                    <p className="text-muted">+91 9100944539</p>
                                                </div>
                                            </div>
                                            <div className="d-flex mt-4">
                                                <div className="icons text-center">
                                                    <i className="uil uil-envelope d-block rounded h4 mb-0"></i>
                                                </div>
                                                <div className="flex-1 ms-3">
                                                    <h5 className="mb-2">Email</h5>
                                                    <p className="text-muted">sales@zencruz.com</p>
                                                </div>
                                            </div>
                                            <div className="d-flex mt-4">
                                                <div className="icons text-center">
                                                    <i className="uil uil-map-marker d-block rounded h4 mb-0"></i>
                                                </div>
                                                <div className="flex-1 ms-3">
                                                    <h5 className="mb-2">Location</h5>
                                                    <p className="text-muted">
                                                        Sundaraiah Vignana Kendram, Survey No 91, Gachibowli Rd,
                                                        Madhava Reddy Colony, Gachibowli, Hyderabad, Telangana 500032
                                                    </p>
                                                </div>
                                            </div>
                                        </div> */}

                                {/* <Leadsignup /> */}

                                {/* </Col> */}
                                {/* </Row> */}


                                <section className="d-table w-100 overflow-hidden" id="home">
                                    <MDBContainer >
                                        {/* <MDBRow className="align-items-center">
                                            <MDBCol sm={12} lg={6} md={6}>
                                                <div className="title-heading">
                                                    <p className="para-desc text-muted">
                                                        Sign up by <strong>31st December 2024</strong> to receive a <strong>10% discount</strong> on all paid plans and enjoy an additional month free!
                                                    </p>
                                                    <p className="para-desc text-muted">
                                                        <strong>Note:</strong> No credit card required.
                                                    </p>
                                                </div>
                                            </MDBCol>

                                            <MDBCol sm={12} lg={6} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                                                <Leadsignup />
                                            </MDBCol>
                                        </MDBRow> */}

                                        <Row className="justify-content-center">
                                            <Col>
                                                <div className="section-title text-center pb-2">
                                                    <h4 className="title mb-3">Sign Up</h4>

                                                </div>
                                            </Col>
                                        </Row>
                                        <Leadsignup />
                                    </MDBContainer>
                                </section>
                            </Container>
                        </section>
                    </React.Fragment>



                    {/* Hero Start  */}
                    <section className="bg-half-170 d-table w-100 overflow-hidden bg-soft-primary" id="home">
                        <MDBContainer >
                            <MDBRow className="align-items-center">
                                <MDBCol sm={12} lg={6} md={6}>
                                    <div className="title-heading">
                                        <h1 className="heading my-3">Streamline Your GYM Operations with EasyMembr
                                        </h1>
                                        <p className="para-desc text-muted">Manage your gym's day-to-day operations with ease using EasyMembr, the all-in-one gym administration app designed for gym owners and managers.

                                        </p>
                                        {/* <div className="mt-4 pt-2">
                                            <div className="mt-4 pt-2">
                                                <ScrollLink
                                                    to="pricing"
                                                    smooth={true}
                                                    duration={50}
                                                    className="btn btn-primary"
                                                >
                                                    Buy Now
                                                </ScrollLink>
                                            </div>

                                        </div> */}
                                    </div>
                                </MDBCol>

                                <MDBCol sm={12} lg={6} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                                    <img src={Laptop} className="homepageImage" />
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </section>



                    {/******Contact start*************/}
                    <React.Fragment>
                        <section className="" id="contact">
                            <Container>
                                <Row className="justify-content-center">
                                    <Col>
                                        <div className="section-title text-center pb-2">
                                            <h4 className="title mb-3">Get In Touch!</h4>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="align-items-center">
                                    <Col lg={6} md={4} className="order-md-2 order-1 mt-4 pt-2">
                                        <div className="p-4 rounded shadow bg-white">
                                            <form
                                                id="contactForm"
                                                className="login-form"
                                                onSubmit={this.handleSubmit}
                                            >
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label className="form-label">
                                                                First Name <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="form-icon position-relative">
                                                                <input
                                                                    type="text"
                                                                    id="firstName"
                                                                    className="form-control"
                                                                    placeholder="First Name"
                                                                    value={firstName}
                                                                    onChange={this.handleChange}
                                                                    required
                                                                />
                                                                <p className="text-danger">{errors.firstName}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label className="form-label">
                                                                Your Email <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="form-icon position-relative">
                                                                <input
                                                                    type="email"
                                                                    id="email"
                                                                    className="form-control"
                                                                    placeholder="Email"
                                                                    value={email}
                                                                    onChange={this.handleChange}
                                                                    required
                                                                />
                                                                <p className="text-danger">{errors.email}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label className="form-label">
                                                                Phone No. <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="form-icon position-relative">
                                                                <input
                                                                    type="number"
                                                                    id="phone"
                                                                    className="form-control"
                                                                    placeholder="Phone No."
                                                                    value={phone}
                                                                    onChange={this.handleChange}
                                                                    required
                                                                />
                                                                <p className="text-danger">{errors.phone}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label className="form-label">
                                                                Inquiry <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="form-icon position-relative">
                                                                <textarea
                                                                    id="inquiry"
                                                                    className="form-control"
                                                                    placeholder="Enter Your Query..."
                                                                    value={inquiry}
                                                                    onChange={this.handleChange}
                                                                    required
                                                                ></textarea>
                                                                <p className="text-danger">{errors.inquiry}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="d-grid">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                            >
                                                                Send
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </Col>
                                    <Col lg={4} md={6} className="col-12 order-md-1 order-2 mt-4 pt-2">
                                        <div className="me-lg-4">
                                            <div className="d-flex">
                                                <div className="icons text-center">
                                                    <i className="uil uil-phone d-block rounded h4 mb-0"></i>
                                                </div>
                                                <div className="flex-1 ms-3">
                                                    <h5 className="mb-2">Phone</h5>
                                                    <p className="text-muted">+91 8019296262</p>
                                                </div>
                                            </div>
                                            <div className="d-flex mt-4">
                                                <div className="icons text-center">
                                                    <i className="uil uil-envelope d-block rounded h4 mb-0"></i>
                                                </div>
                                                <div className="flex-1 ms-3">
                                                    <h5 className="mb-2">Email</h5>
                                                    <p className="text-muted">sales@zencruz.com</p>
                                                </div>
                                            </div>
                                            <div className="d-flex mt-4">
                                                <div className="icons text-center">
                                                    <i className="uil uil-map-marker d-block rounded h4 mb-0"></i>
                                                </div>
                                                <div className="flex-1 ms-3">
                                                    <h5 className="mb-2">Location</h5>
                                                    <p className="text-muted">
                                                        Sundaraiah Vignana Kendram, Survey No 91, Gachibowli Rd,
                                                        Madhava Reddy Colony, Gachibowli, Hyderabad, Telangana 500032
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                        <MDBAlert
                            color={this.state.status}
                            autohide
                            position='top-center'
                            delay={5000}
                            appendToBody
                            open={alerttxt != ""}
                            onClose={this.handleralert}
                        >
                            {alerttxt}
                        </MDBAlert>
                    </React.Fragment>
                    {/******Contact end*************/}

                    {/******Footer start*************/}
                    <React.Fragment>
                        {/* Back to top */}
                        {/* <Link2 to="home" style={{ display: arrow ? 'block' : 'none' }} id="back-to-top" className="back-to-top rounded-pill fs-5">
                            <Icon.ArrowUp className="fea icon-sm icons align-middle" />
                        </Link2> */}
                        {/* Back to top  */}

                        {/* Footer Start  */}
                        <footer className="bg-footer">

                            <div className="footer-py-30 footer-bar bg-footer">
                                <Container className="text-center">
                                    <Row className="align-items-center justify-content-between">
                                        <Col lg={3} md={2} sm={3}>
                                            <div className="">
                                                <Link to="#" className="logo-footer">
                                                    {imageLoaded ? (
                                                        <div className="d-flex align-items-center justify-content-end position-relative">
                                                            <img src={logourl} alt="Logo" onLoad={this.handleImageLoad} onError={this.handleImageError} className="newLogo me-3" />
                                                            <div className="text-light fs-6 ByZencruzText" style={{ bottom: "-15px" }}>By Zencruz</div>
                                                        </div>
                                                    ) : (
                                                        <div className="d-flex align-items-center position-relative">
                                                            <img src={logodark} alt="Default Logo" className="newLogo" />
                                                            <div className="text-primary fs-6 ByZencruzText">By Zencruz</div>
                                                        </div>
                                                    )}
                                                </Link>
                                            </div>
                                        </Col>

                                        <Col lg={6} md={6} sm={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                                            <ul className="list-unstyled footer-list terms-service mb-0">
                                                <li className="list-inline-item mb-0" onClick={this.compopup}>
                                                    <Link to="#" className="text-foot me-2">Privacy</Link>
                                                </li>
                                                <li className="list-inline-item mb-0" onClick={this.compopup1}>
                                                    <Link to="#" className="text-foot me-2">Terms</Link>
                                                </li>
                                                <li className="list-inline-item mb-0" onClick={this.compopup2}>
                                                    <Link to="#" className="text-foot me-2">FAQs</Link>
                                                </li>
                                                {/* <li className="list-inline-item mb-0">
                                            <Link to="/contact" className="text-foot">Contact</Link>
                                        </li> */}
                                            </ul>
                                        </Col>

                                        <Col lg={3} md={4} sm={3} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                                            <div className="text-sm-end">
                                                <p className="mb-0 text-foot">© {(new Date().getFullYear())}{" "} <Link to="#" className="text-reset">Zencruz.</Link></p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </footer>

                        {/* Popups */}
                        {popup && (
                            <Popup
                                title="Privacy Policy for EasyMembr:"
                                ptype="modal-lg"
                                content={<Privacy />}
                                handleClose={this.handler}
                            />
                        )}
                        {popup1 && (
                            <Popup
                                title="Terms and Conditions for EasyMembr:"
                                ptype="modal-lg"
                                content={<Terms />}
                                handleClose={this.handler1}
                            />
                        )}
                        {popup2 && (
                            <Popup
                                title="FAQs for EasyMembr:"
                                ptype="modal-lg"
                                content={<Faqs />}
                                handleClose={this.handler2}
                            />
                        )}
                        {/* Footer End  */}
                    </React.Fragment>
                    {/******Footer end*************/}
                </div>

            </React.Fragment>
        )
    }
}

export default Leads;
